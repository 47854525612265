<form [formGroup]="form">
    <ion-list *ngLet="loading$ | async as loading">
        <ion-item [disabled]="loading" [lines]="linesMode">
            <ion-spinner *ngIf="loading" class="ion-margin-start" name="circular"></ion-spinner>
            <ion-label position="fixed" slot="start">Current Client:</ion-label>
            <ion-select
                [compareWith]="compareWith"
                [disabled]="disabled"
                [formControlName]="selectClientFormKeys.CLIENT"
                [interfaceOptions]="selectPopupOptions"
                [selectedText]="selectedClientText$ | async"
                #clientSelect
                labelPlacement="none"
                interface="alert"
                slot="end">
                <ion-select-option *ngFor="let client of clients$ | async" [value]="client">
                    {{ client.name }}
                </ion-select-option>
            </ion-select>
        </ion-item>
    </ion-list>
</form>