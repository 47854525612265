import { ENVIRONMENT_TOKEN, Environment, STORE_WRAPPER_TOKEN, StoreWrapperInterface } from '@actassa/api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isNumber } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    Subject,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    merge,
    of,
    shareReplay,
    switchMap,
    take,
    tap,
    throttleTime,
} from 'rxjs';

import { TimesheetApproveSourceInterface } from '../../interfaces/timesheet-approve-source.interface';
import { TimesheetApprove } from '../../interfaces/timesheet-approve.interface';
import { SHOW_APPROVE_BUTTON_KEY } from '../record-time/constants';

import { CONFIG_KEY, DATA_THROTTLE_TIMEOUT_KEY, DEFAULT_DATA_THROTTLE_TIMEOUT } from './constants';

@Injectable()
export class TimesheetApproveV2Service {
    public isLoading$: Observable<boolean>;
    public timesheetApproves$: Observable<Array<TimesheetApprove>>;
    public hasApprovePart$: Observable<boolean>;

    private readonly _activator$ = new Subject();
    private readonly _loading$ = new BehaviorSubject<boolean>(false);
    private readonly _storage$ = new BehaviorSubject<Array<TimesheetApprove>>([]);

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly http: HttpClient,
    ) {
        this.isLoading$ = this._loading$.asObservable();
        this.timesheetApproves$ = this._storage$.asObservable();
        this.hasApprovePart$ = this._hasApprovePart$(CONFIG_KEY, SHOW_APPROVE_BUTTON_KEY);
    }

    public getTimesheetApproves(timesheetId: number): void {
        this._activator$.next(timesheetId);
    }

    public loadTimesheetApproves$(timeout: number): Observable<Array<TimesheetApprove>> {
        /**
         * Это комбинация дял перезапроса APPROVE с учетом THROTTLE_TIME
         * Часть с источником в MERGE сделана так по причине:
         *  - возможна смена TIMESHEET в течении периода THROTTLE_TIME - первый _activator$
         *  - при этом нужно перезапрашивать APPROVES по TIMESHEET с учетом THROTTLE_TIME - второй _activator$
         *  - добавлен debounceTime, что бы не дублировать перезапросы при смене TIMESHEET
         */
        return combineLatest([
            this.hasApprovePart$,
            merge(
                this._activator$.pipe(distinctUntilChanged()),
                this._activator$.pipe(throttleTime(timeout)),
            ).pipe(debounceTime(300)),
        ])
            .pipe(
                filter(([hasApprovePart]) => Boolean(hasApprovePart)),
                tap(() => this._loadingStart()),
                switchMap(([, timesheetId]: [boolean, number]) => this._getTimesheetApproves(timesheetId)),
                tap(data => this._saveTimesheetApprovesStorage(data)),
                tap(() => this._loadingStop()),
                catchError(error => {
                    this._loadingStop();

                    this.storeWrapper.showToast('Get approves error. Try later.');

                    return of(null);
                }),
            );
    }

    public saveTimesheetApprove$(timesheetApprove: TimesheetApproveSourceInterface): Observable<Array<TimesheetApprove>> {
        this._loadingStart();

        return this._saveTimesheetApprove$(timesheetApprove)
            .pipe(
                map(() => this._mapTimesheetApproveSourceToTimesheetApproves(timesheetApprove)),
                tap((data: Array<TimesheetApprove>) => this._saveTimesheetApprovesStorage(data)),
                take(1),
                catchError((error: HttpErrorResponse) => {
                    this.storeWrapper.showAlert('Approve save error. Try later.');

                    return of(null);
                }),
                finalize(() => this._loadingStop()),
            );
    }

    public getApproveDataThrottleTimeout$(): Observable<number> {
        return this.storeWrapper.getMenuItemProperty$(CONFIG_KEY, DATA_THROTTLE_TIMEOUT_KEY)
            .pipe(
                map((timeout: number | null) => {
                    if (!timeout || !isNumber(timeout)) {
                        return DEFAULT_DATA_THROTTLE_TIMEOUT;
                    }

                    return timeout * 1000;
                }),
                distinctUntilChanged(),
            );
    }

    private _saveTimesheetApprove$(timesheetApprove: TimesheetApproveSourceInterface): Observable<Array<TimesheetApprove>> {
        const url = this._buildUrl(`v2/timesheet/${timesheetApprove.timesheetId}/approves`);

        return this.http.post<Array<TimesheetApprove>>(url, timesheetApprove);
    }

    private _getTimesheetApproves(timesheetId: number): Observable<Array<TimesheetApprove>> {
        const url = this._buildUrl(`v2/timesheet/${timesheetId}/approves`);

        return this.http.get<Array<TimesheetApprove>>(url);
    }

    private _saveTimesheetApprovesStorage(timesheetApproves: Array<TimesheetApprove>): void {
        const currentStorage = this._storage$.getValue();

        this._storage$.next([...currentStorage, ...timesheetApproves]);
    }

    private _mapTimesheetApproveSourceToTimesheetApproves(timesheetApprove: TimesheetApproveSourceInterface): Array<TimesheetApprove> {
        return timesheetApprove.timesheetHours
            .map(timesheetHour => ({
                timesheetId: timesheetApprove.timesheetId,
                timesheetHourId: timesheetHour.timesheetHourId,
                date: timesheetApprove.date,
                placementId: timesheetApprove.placementId,
                approverId: timesheetApprove.approverId,
                approverName: timesheetApprove.approverName,
                timezone: timesheetApprove.timezone,
                placementOvertimeId: timesheetHour.placementOvertimeId,
            }));
    }

    private _hasApprovePart$(configKey: string, paramKey: string): Observable<boolean> {
        return this.storeWrapper.getMenuItemProperty$(configKey, paramKey)
            .pipe(map(Boolean), distinctUntilChanged(), shareReplay(1));
    }

    private _loadingStart(): void {
        this._loading$.next(true);
    }

    private _loadingStop(): void {
        this._loading$.next(false);
    }

    private _buildUrl(slug: string): string {
        return `${this.environment.apiURL}/${slug}`;
    }
}
