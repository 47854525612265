import { sortBy } from 'lodash-es';
import { PlacementDTOInterface } from '../dto/placement.dto.interface';
import { PlacementStatus } from '../enums/placement-status.enum';
import { SortDirectionEnum } from '../enums/sort-direction.enum';
import { PlacementInterface } from '../interfaces/placement.interface';

export function getPlacementSortMethod(status: PlacementStatus): Function {
    const sortMethod = PlacementsSortStrategy[status];

    if (!sortMethod) {
        return defaultSortMethod;
    }

    return sortMethod;
}

const PlacementsSortStrategy = {
    [PlacementStatus.PLACEMENT_CURRENT]: sortByNextShiftDateTime,
    [PlacementStatus.PLACEMENT_FINISHED]: sortPlacementByKeys,
};

const defaultSortMethod = sortPlacementByKeys;

function sortByNextShiftDateTime(placements: Array<PlacementInterface>, sortDirection: SortDirectionEnum): Array<PlacementInterface> {
    const key = 'nextShiftDateTimePair';
    const multiplier = sortDirection === SortDirectionEnum.ASC ? 1 : -1;

    return placements.sort((a, b) =>
        a[key] && b[key]
            ? multiplier * (
                a[key].startDateTime.getTime() -
                b[key].startDateTime.getTime()
            )
            : 1,
    );
}

function sortPlacementByKeys(placements: Array<PlacementInterface>, sortDirection: SortDirectionEnum): Array<PlacementInterface> {
    const keys: Array<keyof PlacementDTOInterface> = ['placementEndDate', 'placementDescription'];
    const sortedPlacements = sortBy(placements, keys);

    if (sortDirection === SortDirectionEnum.DESC) {
        return sortedPlacements.reverse();
    }

    return sortedPlacements;
}

function sortByFirstShiftDateTime(placements: Array<PlacementInterface>, sortDirection: SortDirectionEnum): Array<PlacementInterface> {
    const key = 'firstShiftDateTimePair';
    const multiplier = sortDirection === SortDirectionEnum.ASC ? 1 : -1;

    return placements.sort((a, b) =>
        a[key] && b[key]
            ? multiplier * (
                a[key].startDateTime.getTime() -
                b[key].startDateTime.getTime()
            )
            : 1,
    );
}
