export const APPROVE_BUTTON_LABEL_KEY = 'approveWeekButtonLabel';
export const CLOSE_BUTTON_LABEL_KEY = 'closeWeekButtonLabel';
export const CONFIG_KEY = 'PLACEMENT';
export const DATA_THROTTLE_TIMEOUT_KEY = 'throttleTimeout';
export const DEFAULT_APPROVE_BUTTON_LABEL = 'Approve All';
export const DEFAULT_CLOSE_BUTTON_LABEL = 'Close';
export const DEFAULT_DATA_THROTTLE_TIMEOUT = 60000;
export const DEFAULT_NOTES = `Step 1: Enter Time<br>Step 2: Supervisor Available, Press 'Onsite Timesheet Approval'<br>Step 3: Supervisor Not Available, Press 'Send for Digital Approval'`;
export const NOTES_KEY = 'timesheetNotes';
export const SHOW_APPROVE_BUTTON_KEY = 'showApprove';
