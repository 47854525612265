import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

import { UserViewStatus } from '../../enums/user-view-status.enum';
import { formatPlacementCardDateTime } from '../../helpers/jobs-placements-date-time.helper';
import { PlacementInterface } from '../../interfaces/placement.interface';
import { WithUserViewStatusInterface } from '../../interfaces/with-user-view-status.interface';

@Component({
    selector: 'jobs-placements-list-item-placement',
    templateUrl: './list-item-placement.component.html',
    styleUrls: ['./list-item-placement.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemPlacementComponent {
    @Input() public item: PlacementInterface;
    @Input() public index: number;

    @Output() public selectEvent = new EventEmitter<PlacementInterface>();

    public select(item: PlacementInterface): void {
        this.selectEvent.emit(item);
    }

    public isHighlightedPlacement(item: WithUserViewStatusInterface): boolean {
        return item.userViewStatus !== UserViewStatus.READ;
    }

    public getFirstFutureShift(item: PlacementInterface): string {
        return formatPlacementCardDateTime(item);
    }
}
