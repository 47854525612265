import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SharedComponentModule } from '../../components/shared-component.module';

import { PlacementsWidgetRoutingModule } from './placements-routing.module';

import { PlacementsWidget } from './placements.widget';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PlacementsWidgetRoutingModule,
        SharedComponentModule,
    ],
    declarations: [PlacementsWidget],
    exports: [PlacementsWidget],
})
export class PlacementsWidgetModule { }
