import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MessagingComponent } from './messaging.component';

const routes: Routes = [
    {
        path: '',
        component: MessagingComponent,
        children: [
            {
                path: '',
                redirectTo: 'select-room',
                pathMatch: 'full',
            },
            {
                path: 'chat-room',
                loadChildren: () => import('./pages/chat-room/chat-room.module').then(m => m.ChatRoomPageModule),
            },
            {
                path: 'select-room',
                loadChildren: () => import('./pages/select-room/select-room.module').then(m => m.SelectRoomPageModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MessagingRoutingModule { }
