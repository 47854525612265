<actassa-detail-list>
    <actassa-detail-list-header *ngIf="job.jobData?.client" [value]="job.jobData.client"> </actassa-detail-list-header>

    <actassa-detail-list-header *ngIf="job.jobData?.jobTitle" [value]="job.jobData.jobTitle"> </actassa-detail-list-header>

    <actassa-detail-list-item *ngIf="job.jobData?.workAddress" [value]="job.jobData.workAddress" title="Address:">
    </actassa-detail-list-item>

    <actassa-detail-list-item *ngIf="isOfferTypeJob && isOneShiftJob" [htmlValue]="jobStartDate" title="Date:"> </actassa-detail-list-item>

    <ng-container *ngIf="isOfferTypeJob && !isOneShiftJob">
        <actassa-detail-list-item [htmlValue]="jobStartDate" title="From:"> </actassa-detail-list-item>
        <actassa-detail-list-item [htmlValue]="jobEndDate" title="To:"> </actassa-detail-list-item>
    </ng-container>

    <ng-container *ngIf="isOfferTypeShift && isOneShiftJob">
        <actassa-detail-list-item [value]="shiftType" title="Shift type:"> </actassa-detail-list-item>
        <actassa-detail-list-item [htmlValue]="shiftDateTime" title="Date and time:"> </actassa-detail-list-item>
    </ng-container>

    <actassa-detail-list-item *ngIf="showPayRate(job)" [value]="job.jobData | payRate" title="Pay rate:"> </actassa-detail-list-item>
</actassa-detail-list>
