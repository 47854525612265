import { Injectable } from '@angular/core';
import { isEmpty, isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

import { TimesheetApproveV3 } from '../interfaces/timesheet-approve-v3.interface';
import { TimesheetHourBreakV3 } from '../interfaces/timesheet-hour-break-v3.interface';
import { TimesheetHourV3 } from '../interfaces/timesheet-hour-v3.interface';
import { TimesheetV3 } from '../interfaces/timesheet-v3.interface';
import { TimesheetTypes } from '../enums/timesheet-types.enum';

@Injectable({
    providedIn: 'root',
})
export class TimesheetsStoreService {
    private timesheets$ = new BehaviorSubject<Array<TimesheetV3>>([]);
    private timesheetHours$ = new BehaviorSubject<Array<TimesheetHourV3>>([]);
    private timesheetHourBreaks$ = new BehaviorSubject<Array<TimesheetHourBreakV3>>([]);
    private timesheetExpenses$ = new BehaviorSubject([]);
    private timesheetApproves$ = new BehaviorSubject<Array<TimesheetApproveV3>>([]);

    constructor() { }

    public saveTimesheets(timesheets: Array<TimesheetV3>): void {
        if (isEmpty(timesheets)) {
            return;
        }

        const currentTimesheets = this.timesheets$.getValue();
        const localTimesheets = currentTimesheets.filter(({ type, placementId, id }) => {
            // Тут испльзуем утверждение, что у нас 1 placement -> 1 shift => 1 timesheet
            const remoteTimesheet = timesheets.find(timesheet => timesheet.placementId === placementId);

            if (isEmpty(remoteTimesheet)) {
                // Нет нового - оставляем тот, что был
                return true;
            }

            if (remoteTimesheet.type !== TimesheetTypes.PLANNED) {
                // Новый - актуальный, локальный удаляем
                return false;
            }

            if (type !== TimesheetTypes.PLANNED) {
                // Новый - понируемый, локальный актуалный - локальный оставляем
                return true;
            }

            // Новый - планируемый и локальный планируемый - возьмем новый
            return false;
        });

        // Так как оставлем локально актуальные - нужно фильтровать новые
        const newTimesheets = timesheets
            .filter(({ placementId }) => !localTimesheets.find(timesheet => timesheet.placementId === placementId));

        this.timesheets$.next([...localTimesheets, ...newTimesheets]);
    }

    public getTimesheets$(): Observable<Array<TimesheetV3>> {
        return this.timesheets$.asObservable().pipe(distinctUntilChanged(isEqual));
    }

    public saveTimesheetHours(timesheetHours: Array<any>): void {
        if (isEmpty(timesheetHours)) {
            return;
        }

        const currentTimesheetHours = this.timesheetHours$.getValue();
        const localTimesheetHours = currentTimesheetHours.filter(({ type, timesheetId, id }) => {
            // Тут испльзуем утверждение, что у нас 1 placement -> 1 shift => 1 timesheet
            const remoteTimesheetHour = timesheetHours.find(timesheetHour => timesheetHour.timesheetId === timesheetId);

            if (isEmpty(remoteTimesheetHour)) {
                // Нет нового - оставляем тот, что был
                return true;
            }

            if (remoteTimesheetHour.type !== TimesheetTypes.PLANNED) {
                // Новый - актуальный, локальный удаляем
                return false;
            }

            if (type !== TimesheetTypes.PLANNED) {
                // Новый - понируемый, локальный актуалный - локальный оставляем
                return true;
            }

            // Новый - планируемый и локальный планируемый - возьмем новый
            return false;
        });

        // Так как оставлем локально актуальные - нужно фильтровать новые
        const newTimesheetHours = timesheetHours
            .filter(({ timesheetId }) => !localTimesheetHours.find(timesheetHour => timesheetHour.timesheetId === timesheetId));

        this.timesheetHours$.next([...localTimesheetHours, ...newTimesheetHours]);
    }

    public getTimesheetHours$(): Observable<Array<TimesheetHourV3>> {
        return this.timesheetHours$.asObservable().pipe(distinctUntilChanged(isEqual));
    }

    public getTimesheetApproves$(): Observable<Array<TimesheetApproveV3>> {
        return this.timesheetApproves$.asObservable().pipe(distinctUntilChanged(isEqual));
    }

    public saveTimesheetApproves(timesheetApproves: Array<TimesheetApproveV3>): void {
        if (isEmpty(timesheetApproves)) {
            return;
        }

        const currentTimesheetApproves = this.timesheetApproves$.getValue();
        const localTimesheetApproves = currentTimesheetApproves.filter(({ timesheetId }) => {
            const remoteTimesheetApprove = timesheetApproves.find(timesheetApprove => timesheetApprove.timesheetId === timesheetId);

            return isEmpty(remoteTimesheetApprove);
        });

        this.timesheetApproves$.next([...localTimesheetApproves, ...timesheetApproves]);
    }

    public saveTimesheetHourBreaks(timesheetHourBreaks: Array<TimesheetHourBreakV3>): void {
        if (isEmpty(timesheetHourBreaks)) {
            return;
        }

        const currentTimesheetHourBreaks = this.timesheetHourBreaks$.getValue();
        const localTimesheetHourBreaks = currentTimesheetHourBreaks.filter(({ id }) => {
            const remoteTimesheetHourBreaks = timesheetHourBreaks.find(timesheetHourBreak => timesheetHourBreak.id === id);

            return isEmpty(remoteTimesheetHourBreaks);
        });

        this.timesheetHourBreaks$.next([...localTimesheetHourBreaks, ...timesheetHourBreaks]);
    }

    public getTimesheetHourBreaks$(): Observable<Array<TimesheetHourBreakV3>> {
        return this.timesheetHourBreaks$.asObservable().pipe(distinctUntilChanged(isEqual));
    }

    public saveTimesheetExpenses(timesheetExpenses: Array<any>): void {
        this.timesheetExpenses$.next(timesheetExpenses);
    }

    public getTimesheetExpenses$(): Observable<Array<any>> {
        return this.timesheetExpenses$.asObservable().pipe(distinctUntilChanged(isEqual));
    }
}
