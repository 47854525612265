import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const CONTENT_DISPOSITION = 'Content-Disposition';
export const FILENAME_REG_EXP = /(filename=")(.*)(")/;

interface ResultFile { fileName: string; file: Blob | null; };

@Injectable({
    providedIn: 'root',
})
export class DownloadFileService {
    constructor(
        private readonly http: HttpClient,
    ) { }

    public downloadFile$(url: string): Observable<ResultFile> {
        return this.http.get(url, {
            responseType: 'blob',
            observe: 'response',
        })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    const file = response.body;
                    const headers = response.headers.get(CONTENT_DISPOSITION);
                    const fileNameMatch = FILENAME_REG_EXP.exec(headers || '');
                    const fileName = fileNameMatch ? fileNameMatch[2] : new Date().getTime().toString();

                    return { fileName, file };
                }),
            );
    }
}
