import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimesheetsRoutingModule } from './timesheets-routing.module';
import { TimesheetsWidget } from './timesheets.widget';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedComponentModule } from '../../components/shared-component.module';
import { SafeModule } from '@actassa/shared';

@NgModule({
    declarations: [TimesheetsWidget],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SafeModule,
        SharedComponentModule,
        TimesheetsRoutingModule,
    ],
    exports: [TimesheetsWidget],
})
export class TimesheetsWidgetModule { }
