import { UserInterface } from '@actassa/api';

import { Message } from '../models/message';
import { Room } from '../models/room';

export function isMessageRead(message: Message, user: UserInterface): boolean {
    return Boolean(message.read[user.uuid]);
}

export function isMessageInRoom(message: Message, room: Room): boolean {
    return Boolean(room) && room.id === message.room;
}
