import { ApplicationsEnum, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const ROUTE_DATA_KEY = 'canActivateRoles';
const ACCESS_DENIED_MESSAGE = 'Access denied.';

@Injectable()
export class RoleAccessGuard  {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
    ) { }

    public canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles = route.data[ROUTE_DATA_KEY] as Array<ApplicationsEnum> || [];

        return this.storeWrapper.app$
            .pipe(
                map((app: ApplicationsEnum) => roles.includes(app)),
                tap((status: boolean) => !status && this.storeWrapper.showToast(ACCESS_DENIED_MESSAGE)),
            );
    }
}
