import { ApplicationsEnum } from '@actassa/api';
import { JobOfferType } from '../enums/job-offer-type.enum';
import { JobStatus } from '../enums/job-status.enum';
import { UserViewStatus } from '../enums/user-view-status.enum';
import { PrimaryConsultantInterface } from '../interfaces/primary-consultant.interface';
import { ShiftStatus } from '../interfaces/shift-status.interface';

export class CollectionDto {
    public appId: string;
    public application: ApplicationsEnum;
    public collectionData: CollectionData;
    public createdAt: number;
    public createdAtFormatted: string;
    public expiredAt: string;
    public id: number;
    public isDeleted: boolean;
    public modifiedAt: number;
    public modifiedAtFormatted: string;
    public offerType: JobOfferType;
    public providerID: string;
    public status: JobStatus;
    public userViewStatus: UserViewStatus;
    public shiftsStatuses?: Array<ShiftStatus>;
}

export interface CollectionData {
    collectionId: number;
    collectionName?: string;
    reviewListStatusIdForRequested: number;
    reviewListStatusIdForDeclined: number;
    reviewListStatusIdForInterestedInSome: number;
    reviewListStatusIdForViewedJobOrShift: number;
    reviewListStatusIdForViewedPlacement: number;
    employmentType: string;
    startDate: string;
    endDate: string;
    notes: Array<string>;
    scheduleType: string;
    diary: Array<CollectionDiary>;
}

export interface CollectionDiary {
    shiftId: number;
    shiftDescription: string;
    shiftName: string;
    shiftStartDateTime: string;
    shiftEndDateTime: string;
    jobId: number;
    jobRefNo: string;
    jobTitle: string;
    client: string;
    clientId: number;
    workAddress: string;
    payRateChargeUnit: string;
    payRateAmount: number;
    numberOfPlaces: number;
    primaryJobConsultant: PrimaryConsultantInterface;
}
