import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isCorrectFileFormat } from '../helpers/check-file-format.helper';

export function allowedFileFormatValidator(allowedFiles): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const isCorrectFormat = isCorrectFileFormat(allowedFiles, value);

        return !isCorrectFormat ? { fileFormat: `Wrong file format! Available formats ${allowedFiles}!` } : null;
    };
}
