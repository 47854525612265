import { SortDirectionEnum } from '../../enums/sort-direction.enum';

export const ALLOWED_GROUP_NAMES = [];
export const DEFAULT_IS_PIN_ALLOWED = true;
export const DEFAULT_IS_POSITION_ALLOWED = true;
export const DEFAULT_LOOK_BACK_DAYS = 0;
export const EMPTY_CONSULTANT_GROUP_NAME = 'Empty';
export const EXCLUDE_PIN_CONSULTANT_GROUP_NAMES = [];
export const EXCLUDE_POSITION_CONSULTANT_GROUP_NAMES = [];
export const INCLUDE_PIN_CONSULTANT_GROUP_NAMES = [];
export const INCLUDE_POSITION_CONSULTANT_GROUP_NAMES = [];
export const MAX_WEEK_BACKWARD = 12;
export const MAX_WEEK_BACKWARD_BY_BRANCH = [];
export const MAX_WEEK_FORWARD = 12;
export const PIN_LENGTH = 6;
export const PROHIBITED_WARD_NAMES = [];
export const SORT_ORDER = SortDirectionEnum.ASC;
