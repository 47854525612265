import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { PlacementInterface } from '../../interfaces/placement.interface';

@Component({
    selector: 'jobs-placements-list-of-placements',
    templateUrl: './list-of-placements.component.html',
    styleUrls: ['./list-of-placements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOfPlacementsComponent {
    @Input() public placements: Array<PlacementInterface>;

    @Output() public selectPlacement = new EventEmitter<PlacementInterface>();

    public trackByID(index: number, item: PlacementInterface): string {
        return item.sortKey
            ? item.sortKey.toString()
            : item.createdAt
                ? item.createdAt.toString()
                : `${item.placementId}`;
    }

    public select(placement: PlacementInterface): void {
        this.selectPlacement.emit(placement);
    }
}
