import { ApplicationsEnum, Environment, ENVIRONMENT_TOKEN, UserInterface } from '@actassa/api';
import { AuthTokenInterface, HttpHeadersDictionary } from '@actassa/api';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { combineLatest, MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { Logout } from 'state/root-state/actions/logout';
import { SetUserOffline } from 'state/root-state/actions/set-user-offline';
import { RootState } from 'state/root-state/root.state';

import { NetworkStatusService } from '../services/network-status.service';

const RETRY_TIMEOUT = 5000;
const MAX_REQUEST_COUNT = 3;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    @Select(RootState.token$) public token$!: Observable<AuthTokenInterface>;
    @Select(RootState.app$) public app$!: Observable<ApplicationsEnum>;
    @Select(RootState.providerId$) public providerId$!: Observable<string>;
    @Select(RootState.user$) public user$!: Observable<UserInterface>;

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly networkStatusService: NetworkStatusService,
    ) { }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.networkStatusService.isNetworkConnected$
            .pipe(
                switchMap((isConnected: boolean) => isConnected ? this.onlineMode$(request, next) : this.offlineMode$()),
            );
    }

    private onlineMode$(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
        return combineLatest([
            this.token$,
            this.app$,
            this.user$,
            this.providerId$,
        ]).pipe(
            take(1),
            switchMap(([{ token, deviceID }, app, user, providerId]: [AuthTokenInterface, ApplicationsEnum, UserInterface, string]) => {
                request = request.clone({
                    headers: request.headers
                        .set(HttpHeadersDictionary.DEVICE_ID, deviceID)
                        .set(HttpHeadersDictionary.APP_ID, this.environment.appId),
                });

                if (app) {
                    request = request.clone({
                        headers: request.headers
                            .set(HttpHeadersDictionary.APPLICATION, app)
                            .set(HttpHeadersDictionary.PROVIDER_ID, providerId),
                    });
                }

                if (token) {
                    request = request.clone({
                        headers: request.headers.set('Authorization', 'Bearer ' + token),
                    });
                }

                // Закоментировано для работы с файлами
                //
                // if (!request.headers.has('Content-Type')) {
                //     request = request.clone({
                //         headers: request.headers.set('Content-Type', 'application/json'),
                //     });
                // }

                // request = request.clone({
                //     headers: request.headers.set('Accept', 'application/json'),
                // });

                return next.handle(request).pipe(
                    this.handleRetryError(RETRY_TIMEOUT),
                    catchError((response: HttpErrorResponse) => {
                        if (response.status === HttpStatusCode.Unauthorized) {
                            // this.authService.refreshToken$().subscribe();
                            this.setUserOffline(user.uuid);
                            this.logout();
                        }

                        const errorMessage = this.buildUserMessage(response);

                        return throwError(() => new Error(errorMessage));
                    }),
                );
            }),
        );
    }

    public handleRetryError(delayTime: number): MonoTypeOperatorFunction<any> {
        let retries = 0;
        const exceedAttemptLimit = MAX_REQUEST_COUNT;

        return retryWhen((error) => error.pipe(
            tap((err) => {
                if (err.status === HttpStatusCode.Unauthorized) {
                    throw err;
                }
            }),
            delay(delayTime),
            concatMap((err) => {
                retries = retries + 1;

                if (retries < exceedAttemptLimit) {
                    return of(err);
                }

                throw err;
            }),
        ));
    }

    private buildUserMessage(response: HttpErrorResponse): string {
        if (response.status < 100) {
            return 'Network error. Try later.';
        }

        return response.message || 'Network error. Try later.';
    }

    @Dispatch()
    private logout(): Logout {
        return new Logout();
    }

    private offlineMode$(): Observable<any> {
        return throwError(() => new Error('Network is offline.'));
    }

    @Dispatch()
    private setUserOffline(uuid: string): SetUserOffline {
        return new SetUserOffline(uuid);
    }
}
