import {
    Environment,
    ENVIRONMENT_TOKEN,
    ServerResponseInterface,
    StoreWrapperInterface,
    STORE_WRAPPER_TOKEN,
    ApplicationsEnum,
} from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { tap, take, catchError, throttleTime, switchMap, finalize, distinctUntilChanged, map } from 'rxjs/operators';

import { ChangePlacementStatus } from '../+state/app-state/actions/change-placement-status';
import { ClearPlacementsLoadingStatus } from '../+state/app-state/actions/clear-placements-loading-status';
import { LoadPlacementsEvent } from '../+state/app-state/actions/load-placements-event';
import { LoadTimeSheetsSuccess } from '../+state/app-state/actions/load-timesheets-success';
import { PickPlacement } from '../+state/app-state/actions/pick-placement';
import { PickTimesheet } from '../+state/app-state/actions/pick-timesheet';
import { PickTimesheetExpense } from '../+state/app-state/actions/pick-timesheet-expense';
import { PickTimesheetHour } from '../+state/app-state/actions/pick-timesheet-hour';
import { SetPlacementUserViewStatus } from '../+state/app-state/actions/set-placement-user-view-status';
import { SetPlacementsLoadingStatus } from '../+state/app-state/actions/set-placements-loading-status';
import { SetTimesheetHourBreaks } from '../+state/app-state/actions/set-timesheet-hour-breaks';
import { UpdatePlacements } from '../+state/app-state/actions/update-placements';
import { PLACEMENTS_LOADING_THROTTLING_TIMEOUT } from '../constants/timer.constants';
import { PlacementDTOInterface } from '../dto/placement.dto.interface';
import { PlacementStatus } from '../enums/placement-status.enum';
import { placementFromDto } from '../helpers/mapper.helper';
import { PlacementInterface } from '../interfaces/placement.interface';
import { AbstractDataLoader } from '../abstracts/data-loader.service';

@Injectable()
export class PlacementsService extends AbstractDataLoader {
    private subscriptions: Subscription;

    constructor(
        @Inject(ENVIRONMENT_TOKEN) protected readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
    ) {
        super(environment);

        this.isActionSubscriptionEnabled$
            .pipe(
                distinctUntilChanged(),
                tap((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        const initSubscription = this.init$().subscribe();

                        this.subscriptions = new Subscription();
                        this.subscriptions.add(initSubscription);
                        this.preloadData();

                        return;
                    }

                    this.subscriptions?.unsubscribe();
                }),
            )
            .subscribe();
    }

    public changeStatus(id: number, status: PlacementStatus): Observable<ServerResponseInterface<Array<PlacementDTOInterface>>> {
        return this.http.post<ServerResponseInterface<Array<PlacementDTOInterface>>>(
            `${this.environment.apiURL}/placement/change/${id}`,
            { status },
        ).pipe(
            take(1),
            tap((response: ServerResponseInterface<Array<PlacementDTOInterface>>) => {
                if (response.status === 'ok') {
                    this.updatePlacements(response.data.map(placementFromDto));

                    return;
                }

                this.storeWrapper.showToast(response.message);
            }),
            catchError((error) => {
                this.storeWrapper.showToast(error.message);

                return of(
                    { data: [] as Array<PlacementDTOInterface>, status: 'error' } as ServerResponseInterface<Array<PlacementDTOInterface>>,
                );
            }),
        );
    }

    public load$(): Observable<ServerResponseInterface<Array<PlacementDTOInterface>>> {
        this.setPlacementsLoading();

        return this.http.get(`${this.environment.apiURL}/placements`)
            .pipe(
                tap((response: ServerResponseInterface<Array<PlacementDTOInterface>>) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === 'ok') {
                        this.updatePlacements(response.data.map(placementFromDto));

                        return;
                    }
                }),
                take(1),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of({ data: [], status: 'error' } as ServerResponseInterface<Array<PlacementDTOInterface>>);
                }),
                finalize(() => this.clearPlacementsLoading()),
            );
    }

    private get isActionSubscriptionEnabled$(): Observable<boolean> {
        const { isAuthenticated$, app$ } = this.storeWrapper;
        const enabledRoles$ = app$
            .pipe(
                map((app: ApplicationsEnum) => app?.startsWith('CANDIDATE')),
            );

        return combineLatest([isAuthenticated$, enabledRoles$])
            .pipe(
                map((keys: Array<boolean>) => keys.every(Boolean)),
            );
    }

    private init$(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadPlacementsEvent),
                    throttleTime(PLACEMENTS_LOADING_THROTTLING_TIMEOUT),
                    switchMap(() => this.load$()),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(ChangePlacementStatus),
                    switchMap(({ id, status }: ChangePlacementStatus) => this.changeStatus(id, status)),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(SetPlacementUserViewStatus),
                    switchMap(({ placementId }: SetPlacementUserViewStatus) => this.setPlacementUserViewStatus(placementId)),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(PickPlacement),
                    tap(() => this.clearPreviousPlacementData()),
                ),
        );
    }

    private setPlacementUserViewStatus(placementId: number): Observable<void> {
        return this.http.patch(`${this.environment.apiURL}/placement/view-status`, {
            placementId,
        })
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of([]);
                }),
            );
    }

    public shiftConfirmationsApply$(placementId: number, shiftId: string): Observable<ServerResponseInterface<void>> {
        return this.http.post<ServerResponseInterface<void>>(
            `${this.environment.apiURL}/placement/${placementId}/shift/apply`,
            { shiftId },
        );
    }

    public shiftConfirmationsDecline$(placementId: number, shiftId: string): Observable<ServerResponseInterface<void>> {
        return this.http.post<ServerResponseInterface<void>>(
            `${this.environment.apiURL}/placement/${placementId}/shift/decline`,
            { shiftId },
        );
    }

    @Dispatch()
    private updatePlacements(data: Array<PlacementInterface>): UpdatePlacements {
        return new UpdatePlacements(data);
    }

    @Dispatch()
    private setPlacementsLoading(): SetPlacementsLoadingStatus {
        this.startLoading();

        return new SetPlacementsLoadingStatus();
    }

    @Dispatch()
    private clearPlacementsLoading(): ClearPlacementsLoadingStatus {
        this.stopLoading();

        return new ClearPlacementsLoadingStatus();
    }

    @Dispatch()
    private clearPreviousPlacementData(): Array<unknown> {
        return [
            new LoadTimeSheetsSuccess([]),
            new PickTimesheet(null),
            new PickTimesheetHour(null),
            new SetTimesheetHourBreaks(null),
            new PickTimesheetExpense(null),
        ];
    }

    @Dispatch()
    private preloadData(): LoadPlacementsEvent {
        return new LoadPlacementsEvent();
    }
}
