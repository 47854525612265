import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';

import { SelectClientRoutingModule } from './select-client-routing.module';
import { SelectClientComponent } from './select-client.component';

@NgModule({
    declarations: [
        SelectClientComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgLetModule,
        ReactiveFormsModule,
        SelectClientRoutingModule,
    ],
    exports: [
        SelectClientComponent,
    ],
})
export class SelectClientComponentModule { }
