import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
    ],
    declarations: [
        LoadingComponent,
    ],
    exports: [
        LoadingComponent,
    ],
})
export class LoadingModule { }
