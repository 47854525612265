import { STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Component, ChangeDetectionStrategy, Input, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'jobs-placements-debug-info',
    templateUrl: './debug-info.component.html',
    styleUrls: ['./debug-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugInfoComponent implements OnInit {
    @Input() public data: any;

    public isDebugMode$: Observable<boolean>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
    ) { }

    public ngOnInit(): void {
        this.isDebugMode$ = this.storeWrapper.isDebugMode$;
    }
}
