import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FilterEventType } from '../../enums/filter-event-type.enum';
import { FilterEventInterface } from '../../interfaces/filter-event.interface';
import { SavedFilterInterface } from '../../interfaces/saved-filter.interface';

@Component({
    selector: 'jobs-placements-saved-filter-list-item',
    templateUrl: './saved-filter-list-item.component.html',
    styleUrls: ['./saved-filter-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedFilterListItemComponent {
    @Input() public filter: SavedFilterInterface;

    @Output() public filterEvent = new EventEmitter<FilterEventInterface>();

    public apply(): void {
        const event = {
            type: FilterEventType.APPLY,
            id: this.filter.id,
        };

        this.filterEvent.emit(event);
    }

    public delete(): void {
        const event = {
            type: FilterEventType.REMOVE,
            id: this.filter.id,
        };

        this.filterEvent.emit(event);
    }
}
