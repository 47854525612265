import { hoursToMinutes, minutesToHours } from 'date-fns';

export function formatRecordedHours(durationValue: number): string {
    if (!durationValue) {
        return '';
    }

    const durationHours: number = minutesToHours(durationValue);
    const durationMinutes: number = Math.round(durationValue - hoursToMinutes(durationHours));
    const duration: string = durationHours
        ? `${durationHours} ${durationHours === 1 ? 'hour' : 'hours'} ${durationMinutes ? `${durationMinutes} min` : ''}`
        : `${durationMinutes} min`;

    return duration;
}
