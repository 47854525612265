import { Pipe, PipeTransform } from '@angular/core';
import { formatJobCardDateTime } from '../helpers/jobs-placements-date-time.helper';
import { JobDataInterface } from '../interfaces/job-data.interface';
import { JobLikeType } from '../types/job-item.type';

@Pipe({
    name: 'shiftTimeDescription',
    pure: true,
})
export class ShiftTimeDescriptionPipe implements PipeTransform {
    public transform(shift: JobLikeType): string {
        return formatJobCardDateTime(shift.jobData as JobDataInterface, shift.offerType);
    }
}

