import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { ActionSheetController, AlertController, IonicModule } from '@ionic/angular';
import { FilesystemService } from '../../services/filesystem.service';
import { FileInputComponent } from './file-input.component';

@NgModule({
    declarations: [FileInputComponent],
    imports: [CommonModule, IonicModule],
    providers: [ActionSheetController, AlertController, AndroidPermissions, FilesystemService],
    exports: [FileInputComponent],
})
export class FileInputModule {}
