import { WeekDay } from '@angular/common';
import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { formatISO9075, startOfDay, startOfWeek } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { CollectionDto } from '../../dto/collection.dto';
import { PlacementExpense, PlacementOvertime } from '../../dto/placement.dto.interface';
import { UserViewStatus } from '../../enums/user-view-status.enum';
import { CollectionInterface } from '../../interfaces/collection.interface';
import { JobInterface } from '../../interfaces/job.interface';
import { JobsPlacementsInterface } from '../../interfaces/jobs-placements-state.interface';
import { PlacementInterface } from '../../interfaces/placement.interface';
import { SavedFilterInterface } from '../../interfaces/saved-filter.interface';
import { ShiftInterface } from '../../interfaces/shift.interface';
import {
    TimesheetExpenseInterface,
    TimesheetHourBreakExtended,
    TimesheetHourInterface,
    TimesheetInterface,
} from '../../interfaces/timesheet.interface';

import { ApplyFilterAction } from './actions/apply-filter.action';
import { ChangeCollectionStatus } from './actions/change-collection-status';
import { ChangeJobStatus } from './actions/change-job-status';
import { ChangePlacementStatus } from './actions/change-placement-status';
import { ChangeShiftStatus } from './actions/change-shift-status';
import { ClearAwaitingJobStatus } from './actions/clear-awaiting-job-status';
import { ClearAwaitingShiftStatus } from './actions/clear-awaiting-shift-status';
import { ClearCollectionsLoadingStatus } from './actions/clear-collections-loading-status';
import { ClearJobsFiltersLoadingStatus } from './actions/clear-jobs-filters-loading-status';
import { ClearJobsLoadingStatus } from './actions/clear-jobs-loading-status';
import { ClearPlacementsLoadingStatus } from './actions/clear-placements-loading-status';
import { ClearShiftsLoadingStatus } from './actions/clear-shifts-loading-status';
import { LoadCollectionsSuccess } from './actions/load-collections-success';
import { LoadFiltersSuccessAction } from './actions/load-filters-success.action';
import { LoadTimeSheetsSuccess } from './actions/load-timesheets-success';
import { PickCollection } from './actions/pick-collection';
import { PickJob } from './actions/pick-job';
import { PickPlacement } from './actions/pick-placement';
import { PickShift } from './actions/pick-shift';
import { PickTimesheet } from './actions/pick-timesheet';
import { PickTimesheetExpense } from './actions/pick-timesheet-expense';
import { PickTimesheetHour } from './actions/pick-timesheet-hour';
import { PickTimesheetHoursApprove } from './actions/pick-timesheet-hours-approve';
import { RemoveFilterAction } from './actions/remove-filter.action';
import { ResetState } from './actions/reset-state';
import { SaveFilterAction } from './actions/save-filter.action';
import { SetAwaitingJobStatus } from './actions/set-awaiting-job-status';
import { SetAwaitingShiftStatus } from './actions/set-awaiting-shift-status';
import { SetCollectionUserViewStatus } from './actions/set-collection-user-view-status';
import { SetCollectionsLoadingStatus } from './actions/set-collections-loading-status';
import { SetDay } from './actions/set-day';
import { SetJobUserViewStatus } from './actions/set-job-user-view-status';
import { SetJobsFiltersLoadingStatus } from './actions/set-jobs-filters-loading-status';
import { SetJobsLoadingStatus } from './actions/set-jobs-loading-status';
import { SetPlacementUserViewStatus } from './actions/set-placement-user-view-status';
import { SetPlacementsLoadingStatus } from './actions/set-placements-loading-status';
import { SetShiftConfirmationId } from './actions/set-shift-confirmation-id';
import { SetShiftUserViewStatus } from './actions/set-shift-user-view-status';
import { SetShiftsLoadingStatus } from './actions/set-shifts-loading-status';
import { SetTimesheetHourBreaks } from './actions/set-timesheet-hour-breaks';
import { SetWeek } from './actions/set-week';
import { UpdateJobs } from './actions/update-jobs';
import { UpdatePlacements } from './actions/update-placements';
import { UpdateShifts } from './actions/update-shifts';
import { buildDefaultPlacementOvertime } from './default-placement-overtime.helper';

const DEFAULT_STATE_DATA: JobsPlacementsInterface = {
    appliedFilterId: null,
    awaitingJobStatusChanges: [],
    awaitingShiftStatusChanges: [],
    collection: null,
    collections: [],
    day: null,
    isCollectionsLoading: false,
    isJobsFiltersLoading: false,
    isJobsLoading: false,
    isPlacementsLoading: false,
    isShiftsLoading: false,
    job: null,
    jobs: [],
    placement: null,
    placements: [],
    savedFilters: [],
    shift: null,
    shiftConfirmationDate: null,
    shiftConfirmationId: null,
    shifts: [],
    timesheet: null,
    timesheetExpense: null,
    timesheetHour: null,
    timesheetHourBreaks: [],
    timesheetHoursApprove: [],
    timesheets: [],
    week: null,
};

@State<JobsPlacementsInterface>({
    name: 'jobsplacements',
    defaults: DEFAULT_STATE_DATA,
})
@Injectable()
export class JobsPlacementsState {
    @Selector()
    public static jobs$(state: JobsPlacementsInterface): Array<JobInterface> {
        return state?.jobs || DEFAULT_STATE_DATA.jobs;
    }

    @Selector()
    public static shifts$(state: JobsPlacementsInterface): Array<ShiftInterface> {
        return state?.shifts || DEFAULT_STATE_DATA.shifts;
    }

    @Selector()
    public static placements$(state: JobsPlacementsInterface): Array<PlacementInterface> {
        return state?.placements || DEFAULT_STATE_DATA.placements;
    }

    @Selector()
    public static collection$(state: JobsPlacementsInterface): CollectionDto | null {
        return state.collection;
    }

    @Selector()
    public static collections$(state: JobsPlacementsInterface): Array<CollectionInterface> {
        return state?.collections || DEFAULT_STATE_DATA.collections;
    }

    @Selector()
    public static job$(state: JobsPlacementsInterface): JobInterface | null {
        return state.job;
    }

    @Selector()
    public static shift$(state: JobsPlacementsInterface): ShiftInterface | null {
        return state.shift;
    }

    @Selector()
    public static placement$(state: JobsPlacementsInterface): PlacementInterface | null {
        return state.placement;
    }

    @Selector()
    public static awaitingJobStatusChanges$(state: JobsPlacementsInterface): Array<any> | null {
        return state.awaitingJobStatusChanges;
    }

    @Selector()
    public static awaitingShiftStatusChanges$(state: JobsPlacementsInterface): Array<any> | null {
        return state.awaitingShiftStatusChanges;
    }

    @Selector()
    public static isCollectionsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isCollectionsLoading;
    }

    @Selector()
    public static isJobsFiltersLoading$(state: JobsPlacementsInterface): boolean {
        return state.isJobsFiltersLoading;
    }

    @Selector()
    public static isJobsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isJobsLoading;
    }

    @Selector()
    public static isShiftsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isShiftsLoading;
    }

    @Selector()
    public static isPlacementsLoading$(state: JobsPlacementsInterface): boolean {
        return state.isPlacementsLoading;
    }

    @Selector()
    public static week$(state: JobsPlacementsInterface): string {
        return state.week;
    }

    @Selector()
    public static day$(state: JobsPlacementsInterface): string {
        return state.day;
    }

    @Selector()
    public static timesheet$({ timesheet, placement }: JobsPlacementsInterface): TimesheetInterface | null {
        if (placement?.placementId === timesheet?.PlacementID) {
            return timesheet;
        }

        return null;
    }

    @Selector()
    public static timesheetHoursApprove$({ timesheetHoursApprove }: JobsPlacementsInterface): Array<number> {
        return timesheetHoursApprove || [];
    }

    @Selector()
    public static timesheetHour$({ timesheet, timesheetHour }: JobsPlacementsInterface): TimesheetHourInterface | null {
        if (timesheet?.TimesheetID === timesheetHour?.TIMESHEETID) {
            return timesheetHour;
        }

        return null;
    }

    @Selector()
    public static timesheetHourBreaks$(
        { timesheetHour, timesheetHourBreaks = [] }: JobsPlacementsInterface,
    ): Array<TimesheetHourBreakExtended> {
        return timesheetHourBreaks.filter(timesheetHourBreak =>
            timesheetHourBreak?.TimesheetHourId === timesheetHour?.TIMESHEETHOURID);
    }

    @Selector()
    public static timesheetExpense$({ timesheetExpense, timesheet }: JobsPlacementsInterface): TimesheetExpenseInterface | null {
        if (timesheetExpense?.TIMESHEETID === timesheet?.TimesheetID) {
            return timesheetExpense;
        }

        return null;
    }

    @Selector()
    public static timesheets$(state: JobsPlacementsInterface): Array<TimesheetInterface> {
        return state.timesheets || [];
    }

    @Selector()
    public static placementOvertimes$({ placement }: JobsPlacementsInterface): Array<PlacementOvertime> {
        /**
         * Update 6 May 2022
         *
         * If a placement has an Overtime/Special rate that has a non-null/non-zero value of rateUnitListValueIdOverride,
         * it should not be shown in the menu of rates.
         */
        const defaultPlacementOvertime = buildDefaultPlacementOvertime(placement);

        return [
            defaultPlacementOvertime,
            ...((placement?.placementOvertime) || []),
        ].filter(({ rateUnitListValueIdOverride }) => !rateUnitListValueIdOverride);
    }

    @Selector()
    public static placementExpenses$({ placement }: JobsPlacementsInterface): Array<PlacementExpense> {
        return placement?.placementExpenses || [];
    }

    @Selector()
    public static shiftConfirmationId$(state: JobsPlacementsInterface): string | null {
        return state.shiftConfirmationId;
    }

    @Selector()
    public static shiftConfirmationDate$(state: JobsPlacementsInterface): string | null {
        return state.shiftConfirmationDate;
    }

    @Selector()
    public static savedFilters$(state: JobsPlacementsInterface): Array<SavedFilterInterface> {
        return state.savedFilters || [];
    }

    @Selector()
    public static appliedFilter$(state: JobsPlacementsInterface): SavedFilterInterface | null {
        if (!state.appliedFilterId) {
            return null;
        }

        const filter = state.savedFilters?.find(savedFilter => savedFilter.id === state.appliedFilterId);

        if (!filter) {
            return null;
        }

        return { ...filter };
    }

    @Action(LoadCollectionsSuccess)
    public updateCollections(stateContext: StateContext<JobsPlacementsInterface>, { collections }: LoadCollectionsSuccess): void {
        stateContext.patchState({
            collections: [...collections],
        });
    }

    @Action(UpdateJobs)
    public updateJobs(stateContext: StateContext<JobsPlacementsInterface>, { jobs }: UpdateJobs): void {
        stateContext.patchState({
            jobs: [...jobs],
        });
    }

    @Action(UpdateShifts)
    public updateShifts(stateContext: StateContext<JobsPlacementsInterface>, { shifts }: UpdateShifts): void {
        stateContext.patchState({
            shifts: [...shifts],
        });
    }

    @Action(ChangeCollectionStatus)
    public changeCollectionStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangeCollectionStatus): Observable<void> {
        const { id, status, shiftsStatuses } = action;
        const collections = cloneDeep(stateContext.getState().collections);
        const collection = collections?.find(j => j.id === id);

        if (collection) {
            collection.status = status;

            if (shiftsStatuses) {
                collection.shiftsStatuses = shiftsStatuses;
            }

            return stateContext.dispatch(new LoadCollectionsSuccess(collections));
        }

        return of();
    }

    @Action(ChangeJobStatus)
    public changeJobStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangeJobStatus): Observable<void> {
        const { id, status, shiftsStatuses } = action;
        const jobs = cloneDeep(stateContext.getState().jobs);
        const job = jobs.find(j => j.id === id);

        if (job) {
            job.status = status;

            if (shiftsStatuses) {
                job.shiftsStatuses = shiftsStatuses;
            }

            return stateContext.dispatch(new UpdateJobs(jobs));
        }

        return of();
    }

    @Action(ChangeShiftStatus)
    public changeShiftStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangeShiftStatus): Observable<void> {
        const { id, status } = action;
        const shifts = cloneDeep(stateContext.getState().shifts);
        const shift = shifts.find(j => j.id === id);

        if (shift) {
            shift.status = status;

            return stateContext.dispatch(new UpdateShifts(shifts));
        }

        return of();
    }

    @Action(ChangePlacementStatus)
    public changePlacementStatus$(stateContext: StateContext<JobsPlacementsInterface>, action: ChangePlacementStatus): Observable<void> {
        const { id, status } = action;
        const placements = cloneDeep(stateContext.getState().placements);
        const placement = placements.find(p => p.placementId === id);

        if (placement) {
            placement.status = status;

            return stateContext.dispatch(new UpdatePlacements(placements));
        }

        return of();
    }

    @Action(UpdatePlacements)
    public updatePlacements(stateContext: StateContext<JobsPlacementsInterface>, action: UpdatePlacements): void {
        stateContext.patchState({
            placements: [...action.placements],
        });
    }

    @Action(ResetState)
    public resetState(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.setState(DEFAULT_STATE_DATA);
    }

    // @Action(Logout)
    // public logout(stateContext: StateContext<JobsPlacementsInterface>): void {
    //     stateContext.setState(DEFAULT_STATE_DATA);
    // }

    @Action(PickCollection)
    public pickCollection(stateContext: StateContext<JobsPlacementsInterface>, { collection }: PickCollection): void {
        stateContext.patchState({ collection });
    }

    @Action(PickJob)
    public pickJob(stateContext: StateContext<JobsPlacementsInterface>, { job }: PickJob): void {
        stateContext.patchState({ job });
    }

    @Action(PickShift)
    public pickShift(stateContext: StateContext<JobsPlacementsInterface>, { shift }: PickShift): void {
        stateContext.patchState({ shift });
    }

    @Action(PickPlacement)
    public pickPlacement(stateContext: StateContext<JobsPlacementsInterface>, { placement }: PickPlacement): void {
        stateContext.patchState({ placement });
    }

    @Action(PickTimesheet)
    public pickTimesheet(stateContext: StateContext<JobsPlacementsInterface>, { timesheet }: PickTimesheet): void {
        stateContext.patchState({ timesheet });
    }

    @Action(PickTimesheetExpense)
    public pickTimesheetExpense(stateContext: StateContext<JobsPlacementsInterface>, { timesheetExpense }: PickTimesheetExpense): void {
        stateContext.patchState({ timesheetExpense });
    }

    @Action(PickTimesheetHour)
    public pickTimesheetHour(stateContext: StateContext<JobsPlacementsInterface>, { timesheetHour }: PickTimesheetHour): void {
        stateContext.patchState({ timesheetHour });
    }

    @Action(PickTimesheetHoursApprove)
    public pickTimesheetHoursApprove(
        stateContext: StateContext<JobsPlacementsInterface>,
        { timesheetHours }: PickTimesheetHoursApprove): void {
        stateContext.patchState({ timesheetHoursApprove: timesheetHours });
    }

    @Action(SetTimesheetHourBreaks)
    public setTimesheetHourBreaks(
        stateContext: StateContext<JobsPlacementsInterface>,
        { timesheetHourBreaks }: SetTimesheetHourBreaks,
    ): void {
        stateContext.patchState({ timesheetHourBreaks });
    }

    @Action(SetAwaitingJobStatus)
    public setAwaitingJobStatus(stateContext: StateContext<JobsPlacementsInterface>, action: SetAwaitingJobStatus): void {
        const { data } = action;
        const current = stateContext.getState().awaitingJobStatusChanges.filter(a => a.jobID !== data.jobID);

        stateContext.patchState({
            awaitingJobStatusChanges: [...current, data],
        });
    }

    @Action(SetAwaitingShiftStatus)
    public setAwaitingShiftStatus(stateContext: StateContext<JobsPlacementsInterface>, action: SetAwaitingShiftStatus): void {
        const { data } = action;
        const current = stateContext.getState().awaitingShiftStatusChanges.filter(a => a.shiftId !== data.shiftId);

        stateContext.patchState({
            awaitingShiftStatusChanges: [...current, data],
        });
    }

    @Action(ClearAwaitingJobStatus)
    public clearAwaitingJobStatus(stateContext: StateContext<JobsPlacementsInterface>, action: ClearAwaitingJobStatus): void {
        const { data } = action;
        const statuses = stateContext.getState().awaitingJobStatusChanges.filter(s => s.jobID !== data.jobID);

        stateContext.patchState({
            awaitingJobStatusChanges: [
                ...statuses,
            ],
        });
    }

    @Action(ClearAwaitingShiftStatus)
    public clearAwaitingShiftStatus(stateContext: StateContext<JobsPlacementsInterface>, action: ClearAwaitingShiftStatus): void {
        const { data } = action;
        const statuses = stateContext.getState().awaitingShiftStatusChanges.filter(s => s.shiftId !== data.shiftId);

        stateContext.patchState({
            awaitingShiftStatusChanges: [
                ...statuses,
            ],
        });
    }

    @Action(SetCollectionsLoadingStatus)
    public setCollectionsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isCollectionsLoading: true });
    }

    @Action(SetJobsLoadingStatus)
    public setJobsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsLoading: true });
    }

    @Action(SetShiftsLoadingStatus)
    public setShiftsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isShiftsLoading: true });
    }

    @Action(ClearCollectionsLoadingStatus)
    public clearCollectionsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isCollectionsLoading: false });
    }

    @Action(ClearJobsLoadingStatus)
    public clearJobsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsLoading: false });
    }

    @Action(ClearShiftsLoadingStatus)
    public clearShiftsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isShiftsLoading: false });
    }

    @Action(ClearJobsFiltersLoadingStatus)
    public clearJobsFilersLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsFiltersLoading: false });
    }

    @Action(SetJobsFiltersLoadingStatus)
    public setJobsFiltersLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isJobsFiltersLoading: false });
    }

    @Action(SetPlacementsLoadingStatus)
    public setPlacementsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isPlacementsLoading: true });
    }

    @Action(ClearPlacementsLoadingStatus)
    public clearPlacementsLoadingStatus(stateContext: StateContext<JobsPlacementsInterface>): void {
        stateContext.patchState({ isPlacementsLoading: false });
    }

    @Action(SetCollectionUserViewStatus)
    public setCollectionUserViewStatus(
        stateContext: StateContext<JobsPlacementsInterface>,
        { collectionId }: SetCollectionUserViewStatus,
    ): void {
        const selectedCollection = stateContext.getState().collections.filter(item => item.id === collectionId)[0];
        const collections = stateContext.getState().collections.filter(item => item.id !== collectionId);
        const collection = {
            ...selectedCollection,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            collection,
            collections: [
                ...collections,
                collection,
            ],
        });
    }
    @Action(SetJobUserViewStatus)
    public setJobUserViewStatus(stateContext: StateContext<JobsPlacementsInterface>, { jobId }: SetJobUserViewStatus): void {
        const selectedJob = stateContext.getState().jobs.filter(item => item.id === jobId)[0];
        const jobs = stateContext.getState().jobs.filter(item => item.id !== jobId);
        const job = {
            ...selectedJob,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            job,
            jobs: [
                ...jobs,
                job,
            ],
        });
    }

    @Action(SetShiftUserViewStatus)
    public setShiftUserViewStatus(stateContext: StateContext<JobsPlacementsInterface>, { shiftId }: SetShiftUserViewStatus): void {
        const selectedShift = stateContext.getState().shifts.filter(item => item.id === shiftId)[0];
        const shifts = stateContext.getState().shifts.filter(item => item.id !== shiftId);
        const shift = {
            ...selectedShift,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            shift,
            shifts: [
                ...shifts,
                shift,
            ],
        });
    }

    @Action(SetPlacementUserViewStatus)
    public setPlacementUserViewStatus(
        stateContext: StateContext<JobsPlacementsInterface>,
        { placementId }: SetPlacementUserViewStatus,
    ): void {
        const selectedPlacement = stateContext.getState().placements.filter(item => item.placementId === placementId)[0];
        const placements = stateContext.getState().placements.filter(item => item.placementId !== placementId);
        const placement = {
            ...selectedPlacement,
            userViewStatus: UserViewStatus.READ,
        };

        stateContext.patchState({
            placement,
            placements: [
                ...placements,
                placement,
            ],
        });
    }

    @Action(SetWeek)
    public setWeek(stateContext: StateContext<JobsPlacementsInterface>, { week }: SetWeek): void {
        stateContext.patchState({ week: formatISO9075(startOfWeek(week, { weekStartsOn: WeekDay.Monday })) });
    }

    @Action(SetDay)
    public setDay(stateContext: StateContext<JobsPlacementsInterface>, { day }: SetDay): void {
        stateContext.patchState({ day: formatISO9075(startOfDay(day)) });
    }

    @Action(LoadTimeSheetsSuccess)
    public loadTimeSheetsSuccess(stateContext: StateContext<JobsPlacementsInterface>, action: LoadTimeSheetsSuccess): void {
        const { timesheets } = action;

        stateContext.patchState({ timesheets: [...timesheets] });
    }

    @Action(SetShiftConfirmationId)
    public setShiftConfirmationId(
        stateContext: StateContext<JobsPlacementsInterface>,
        { shiftId, shiftDate }: SetShiftConfirmationId,
    ): void {
        stateContext.patchState({
            shiftConfirmationId: shiftId,
            shiftConfirmationDate: shiftDate,
        });
    }

    @Action(LoadFiltersSuccessAction)
    public loadSavedFilters(stateContext: StateContext<JobsPlacementsInterface>, { payload }: LoadFiltersSuccessAction): void {
        stateContext.patchState({ savedFilters: payload });
    }

    @Action(SaveFilterAction)
    public saveFilter(stateContext: StateContext<JobsPlacementsInterface>, { filter }: SaveFilterAction): void {
        const { savedFilters } = stateContext.getState();
        const otherFilters = savedFilters?.filter(({ id }) => id !== filter.id) || [];

        stateContext.patchState({
            appliedFilterId: filter.id,
            savedFilters: [
                ...otherFilters,
                filter,
            ],
        });
    }

    @Action(RemoveFilterAction)
    public removeFilter(stateContext: StateContext<JobsPlacementsInterface>, { filterId }: RemoveFilterAction): void {
        const { appliedFilterId, savedFilters } = stateContext.getState();
        const updatedFilters = savedFilters.filter(({ id }) => id !== filterId);
        const updatedAppliedFilterId = appliedFilterId === filterId ? null : appliedFilterId;

        stateContext.patchState({
            appliedFilterId: updatedAppliedFilterId,
            savedFilters: updatedFilters,
        });
    }

    @Action(ApplyFilterAction)
    public applyFilter(stateContext: StateContext<JobsPlacementsInterface>, { filterId }: ApplyFilterAction): void {
        stateContext.patchState({
            appliedFilterId: filterId,
        });
    }
}
