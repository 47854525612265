export const APPROVE_PAGE_TITLE_KEY = 'approvePageTitle';
export const CONFIG_KEY = 'PLACEMENT';
export const DEFAULT_APPROVE_PAGE_TITLE = 'Approve';
export const DEFAULT_TIMESHEET_CHECK_STATUS = false;
export const EMPTY_APPROVER_ID = 0;
export const EMPTY_APPROVER_NAME = 'Other';
export const DATA_THROTTLE_TIMEOUT_KEY = 'throttleTimeout';
export const DEFAULT_DATA_THROTTLE_TIMEOUT = 60000;
export const OTHER_APPROVER = {
    disabled: false,
    id: EMPTY_APPROVER_ID,
    name: EMPTY_APPROVER_NAME,
    isPrimary: false,
};
export const DEFAULT_TIMESHEET_APPROVE_CONFIG = {
    clientId: 0,
    isOtherHidden: false,
    approvers: [],
};
