import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { FeatureMenuComponent } from './feature-menu.component';

@NgModule({
    declarations: [FeatureMenuComponent],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [FeatureMenuComponent],
})
export class FeatureMenuModule { }
