import { Environment, ENVIRONMENT_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ResponseDTO {
    status: string,
    message: string,
    data: { timezone?: string },
};

@Injectable({
    providedIn: 'root',
})
export class TimezoneDataService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }

    public getTimezone$(): Observable<Partial<{ timezone: string }>> {
        return this.http.get<ResponseDTO>(`${this.environment.apiURL}/auth/timezone`)
            .pipe(
                map(({ data }) => data),
            );
    }
}
