import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, Input, EventEmitter, Output } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { first } from 'lodash-es';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LoadPlacementsEvent } from '../../+state/app-state/actions/load-placements-event';
import { PickPlacement } from '../../+state/app-state/actions/pick-placement';
import { JobsPlacementsState } from '../../+state/app-state/app.state';
import { MODULE_ROOT_PATH } from '../../constants/routing.constants';
import { RoutesDictionary } from '../../dictionaries/routes.dictionary';
import { PlacementStatus } from '../../enums/placement-status.enum';
import { SortDirectionEnum } from '../../enums/sort-direction.enum';
import { getPlacementSortMethod } from '../../helpers/placements-sort.strategy';
import { PlacementInterface } from '../../interfaces/placement.interface';

@Component({
    selector: 'jobs-placements-placements-widget',
    templateUrl: './placements.widget.html',
    styleUrls: ['./placements.widget.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacementsWidget implements OnInit, OnDestroy {
    @Select(JobsPlacementsState.placements$) private placements$: Observable<Array<PlacementInterface>>;

    @Input() public emptyText = '';
    @Input() public sortDirection: SortDirectionEnum = SortDirectionEnum.ASC;
    @Input() public statuses: Array<PlacementStatus> = [PlacementStatus.PLACEMENT_CURRENT];

    @Output() public placementsLength = new EventEmitter<number>();

    public placementsList$: Observable<Array<PlacementInterface>>;

    private placementUrl: string;
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
    ) { }

    public ngOnInit(): void {
        this.initPlacementUrl();
        this.placementsList$ = this.initPlacementsList();
        this.loadPlacements();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public select(placement: PlacementInterface): void {
        this.pickPlacement(placement);
        this.navigatePlacement();
    }

    private initPlacementUrl(): void {
        const subscription = this.storeWrapper.baseUrl$
            .subscribe(baseUrl => {
                this.placementUrl = `${baseUrl}/${MODULE_ROOT_PATH}/${RoutesDictionary.PLACEMENT}`;
            });

        this.subscriptions.add(subscription);
    }

    private initPlacementsList(): Observable<Array<PlacementInterface>> {
        return combineLatest([
            this.placements$,
            this.storeWrapper.providerId$,
        ]).pipe(
            map(([placements, providerId]: [Array<PlacementInterface>, string]) => {
                const sort = getPlacementSortMethod(first(this.statuses));
                const actualPlacements = placements
                    .filter((placement) => {
                        if (placement?.providerID !== providerId) {
                            return false;
                        }

                        /*
                         * NOTE:
                         * Фильтрация производится по статусу и по наличию следующей смены
                         * Идентично фильтрации в MenuItemsService
                         */
                        if (this.statuses.includes(PlacementStatus.PLACEMENT_CURRENT)
                            && this.statuses.includes(placement?.status)
                            && Boolean(placement.nextShiftDateTimePair)) {
                            return true;
                        }

                        if (this.statuses.includes(PlacementStatus.PLACEMENT_FINISHED)
                            && (
                                this.statuses.includes(placement?.status)
                                || (placement.status === PlacementStatus.PLACEMENT_CURRENT && !placement.nextShiftDateTimePair)
                            )) {
                            return true;
                        }

                        return false;
                    });

                return sort(actualPlacements, this.sortDirection);
            }),
            tap((placements: Array<PlacementInterface>) => {
                this.placementsLength.emit(placements.length);
            }),
        );
    }

    @Dispatch()
    public pickPlacement(placement: PlacementInterface | null): PickPlacement {
        return new PickPlacement(placement);
    }

    @Dispatch()
    private loadPlacements(): LoadPlacementsEvent {
        return new LoadPlacementsEvent();
    }

    @Dispatch()
    private navigatePlacement(): Navigate {
        return new Navigate([this.placementUrl]);
    }
}
