import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MAIN_PAGE } from './constants/routing.constants';
import { RoutesDictionary } from './dictionaries/routes.dictionary';
import { JobStatus } from './enums/job-status.enum';
import { PlacementStatus } from './enums/placement-status.enum';
import { SectionsEnum } from './enums/sections.enum';
import { SortDirectionEnum } from './enums/sort-direction.enum';
import { JobsPlacementsComponent } from './jobs-placements.component';
import { canActivateMenuItems } from './guards/timesheet-route.guard';

const routes: Routes = [
    {
        path: '',
        component: JobsPlacementsComponent,
        children: [
            {
                path: '',
                redirectTo: MAIN_PAGE,
                pathMatch: 'full',
            },
            {
                path: RoutesDictionary.OPPORTUNITIES,
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.OPEN_OPPORTUNITY],
                    titleKey: SectionsEnum.OPPORTUNITIES,
                    sortDirection: SortDirectionEnum.ASC,
                },
                // resolve: { message: JobsLoaderResolver }
            },
            {
                path: RoutesDictionary.APPLICATIONS,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.CURRENT_APPLICATION, JobStatus.INTERESTED_SOME_SHIFTS],
                    titleKey: SectionsEnum.APPLICATIONS,
                    sortDirection: SortDirectionEnum.ASC,
                },
            },
            {
                path: RoutesDictionary.ASSIGNMENTS,
                loadChildren: () =>
                    import('./pages/placements/placements.module').then(
                        (m) => m.PlacementsPageModule,
                    ),
                data: {
                    statuses: [PlacementStatus.PLACEMENT_CURRENT],
                    titleKey: SectionsEnum.ASSIGNMENTS,
                    sortDirection: SortDirectionEnum.ASC,
                },
            },
            {
                path: RoutesDictionary.FINISHED_ASSIGNMENTS,
                loadChildren: () =>
                    import('./pages/placements/placements.module').then(
                        (m) => m.PlacementsPageModule,
                    ),
                data: {
                    statuses: [PlacementStatus.PLACEMENT_FINISHED],
                    titleKey: SectionsEnum.FINISHED_ASSIGNMENTS,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.UNANSWERED_OPPORTUNITIES,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.IGNORED_OPPORTUNITY],
                    titleKey: SectionsEnum.UNANSWERED_OPPORTUNITIES,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.DECLINED_OPPORTUNITIES,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.DECLINED_OPPORTUNITY],
                    titleKey: SectionsEnum.DECLINED_OPPORTUNITIES,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.SUCCESSFUL_APPLICATIONS,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.SUCCESSFUL_APPLICATION],
                    titleKey: SectionsEnum.SUCCESSFUL_APPLICATIONS,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.UNSUCCESSFUL_APPLICATIONS,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.UNSUCCESSFUL_APPLICATION],
                    titleKey: SectionsEnum.UNSUCCESSFUL_APPLICATIONS,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.UNANSWERED_APPLICATIONS,
                loadChildren: () =>
                    import('./pages/jobs/jobs.module').then((m) => m.JobsPageModule),
                data: {
                    statuses: [JobStatus.UNANSWERED_APPLICATION],
                    titleKey: SectionsEnum.UNANSWERED_APPLICATIONS,
                    sortDirection: SortDirectionEnum.DESC,
                },
            },
            {
                path: RoutesDictionary.COLLECTION,
                loadChildren: () =>
                    import('./pages/collection/collection.module').then((m) => m.CollectionPageModule),
            },
            {
                path: RoutesDictionary.JOB,
                loadChildren: () =>
                    import('./pages/job/job.module').then((m) => m.JobPageModule),
            },
            {
                path: RoutesDictionary.SHIFT,
                loadChildren: () =>
                    import('./pages/shift/shift.module').then((m) => m.ShiftPageModule),
            },
            {
                path: RoutesDictionary.PLACEMENT,
                loadChildren: () =>
                    import('./pages/placement/placement.module').then((m) => m.PlacementPageModule,
                    ),
            },
            {
                path: RoutesDictionary.RECORD_TIME,
                loadChildren: () =>
                    import('./pages/record-time/record-time.module').then((m) => m.RecordTimePageModule,
                    ),
            },
            {
                path: RoutesDictionary.RECORD_TIME_DAILY,
                loadChildren: () =>
                    import('./pages/record-time-daily/record-time-daily.module').then((m) => m.RecordTimeDailyPageModule),
            },
            {
                path: RoutesDictionary.RECORD_TIME_ENTITY,
                loadChildren: () =>
                    import('./pages/record-time-entity/record-time-entity.module').then((m) => m.RecordTimeEntityPageModule),
            },
            {
                path: RoutesDictionary.RECORD_EXPENSE,
                loadChildren: () =>
                    import('./pages/record-expense/record-expense.module').then((m) => m.RecordExpensePageModule),
            },
            {
                path: RoutesDictionary.TIMESHEET_VIEW_TIMESHEET,
                loadChildren: () =>
                    import('./pages/view-timesheet/view-timesheet.module').then((m) => m.ViewTimesheetPageModule),
            },
            {
                path: RoutesDictionary.SHIFT_CONFIRMATIONS,
                loadChildren: () =>
                    import('./pages/shift-confirmations/shift-confirmations.module').then((m) => m.ShiftConfirmationsModule),
            },
            {
                path: `${RoutesDictionary.TIMESHEET}/:id/${RoutesDictionary.TIMESHEET_APPROVE_V2}`,
                loadChildren: () =>
                    import('./pages/timesheet-approve-v2/timesheet-approve-v2.module').then((m) => m.TimesheetApproveV2PageModule),
            },
            {
                path: `v3/${RoutesDictionary.TIMESHEETS}`,
                data: {
                    key: SectionsEnum.TIMESHEETS,
                },
                canActivate: [canActivateMenuItems],
                loadChildren: () =>
                    import('./pages/v3/timesheets/timesheets.module').then((m) => m.TimesheetsModule),
            },
            {
                path: `v3/${RoutesDictionary.TIMESHEET_HOURS}`,
                data: {
                    key: SectionsEnum.TIMESHEETS,
                },
                canActivate: [canActivateMenuItems],
                loadComponent: async () =>
                    (await import('./pages/v3/timesheet-hours/timesheet-hours.page')).TimesheetHoursPage,
            },
            {
                path: `v3/${RoutesDictionary.TIMESHEET_APPROVE}`,
                data: {
                    key: SectionsEnum.TIMESHEETS,
                },
                canActivate: [canActivateMenuItems],
                loadComponent: async () =>
                    (await import('./pages/v3/timesheet-approve/timesheet-approve.page')).TimesheetApproveV3Page,
            },
            {
                path: `v3/${RoutesDictionary.TIMESHEET_HOUR}`,
                data: {
                    key: SectionsEnum.TIMESHEETS,
                },
                canActivate: [canActivateMenuItems],
                loadComponent: async () =>
                    (await import('./pages/v3/timesheet-hour/timesheet-hour.page')).TimesheetHourPage,
            },
            {
                path: `v3/${RoutesDictionary.TIMESHEET_HOUR_ADD}`,
                data: {
                    key: SectionsEnum.TIMESHEETS,
                },
                canActivate: [canActivateMenuItems],
                loadComponent: async () =>
                    (await import('./pages/v3/timesheet-hour/timesheet-hour.page')).TimesheetHourPage,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class JobsPlacementsRoutingModule { }
