import { DictionaryInterface, Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { formatISO9075 } from 'date-fns';
import { zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class InformErrorService {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }

    public handleErrorInformRequest(
        data: DictionaryInterface<any>,
    ): void {
        zip(
            this.storeWrapper.app$,
            this.storeWrapper.providerId$,
            this.storeWrapper.timezone$,
            this.storeWrapper.user$,
        )
            .pipe(
                map(([app, providerId, timezone, user]) => ({
                    ...data,
                    application: app,
                    providerId: providerId,
                    serverTimezone: timezone,
                    timestampInUserTimezone: formatISO9075(new Date()),
                    user,
                })),
                switchMap(extendedData => {
                    const url = this.buildUrl();

                    return this.http.post(url, extendedData);
                }),
                take(1),
            )
            .subscribe();
    }

    private buildUrl(): string {
        return `${this.environment.apiURL}/inform/error`;
    }
}
