import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'jobs-placements-list-item-text',
    templateUrl: './list-item-text.component.html',
    styleUrls: ['./list-item-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemTextComponent {
    @Input() public text = '';
    @Input() public isShow = false;
}
