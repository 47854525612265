import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

const NETWORK_STATUS_CHANGE_DEBOUNCE_TIME = 2000;

@Injectable({
    providedIn: 'root',
})
export class NetworkStatusService {
    public isNetworkConnected$: Observable<boolean>;
    private _connected$ = new BehaviorSubject<boolean>(true);

    constructor() {
        this.initStartStatus();
        this.initStatusChanges();
        this.isNetworkConnected$ = this._connected$
            .pipe(
                debounceTime(NETWORK_STATUS_CHANGE_DEBOUNCE_TIME),
                distinctUntilChanged(),
            );
    }

    private initStartStatus(): void {
        Network.getStatus().then((status: ConnectionStatus) => {
            this._connected$.next(status.connected);
        });
    }

    private initStatusChanges(): void {
        const networkStatusListener = Network.addListener(
            'networkStatusChange',
            (status: ConnectionStatus) => {
                this._connected$.next(status.connected);
            },
        );
    }
}
