<section *ngIf="hasDailyShift" class="ion-margin-top">
    <div>
        <ion-text color="primary">Shifts:</ion-text>
    </div>
    <ul>
        <li *ngFor="let shift of dailyShifts">
            <ion-text color="dark">
                {{ getShiftDescription(shift) }}
            </ion-text>
        </li>
    </ul>
</section>
