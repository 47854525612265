import { UserInterface } from '@actassa/api';
import { format } from 'date-fns';
import { isEmpty, isObject, isString } from 'lodash-es';

export function paramReplacer(input: any, user: UserInterface): any {
    return isString(input)
        ? replace(input, user)
        : isObject(input) && !isEmpty(input)
            ? Object.keys(input).reduce(
                (accumulator, key: string) => ({
                    ...accumulator,
                    [key]: paramReplacer(input[key], user),
                }),
                {},
            )
            : input;
}

function replace(input: string, { firstName, lastName, id, email, consultantID, consultantEmail }: UserInterface): string | number {
    if (input === '%CURRENT_USER_ID%') {
        return id;
    }

    const date = new Date();
    const fullFormatWithTimezone = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

    return input
        .replace(/%CURRENT_DATE%/g, format(date, fullFormatWithTimezone))
        .replace(/%CURRENT_TIME%/g, format(date, fullFormatWithTimezone))
        .replace(/%CURRENT_DATE_TIME%/g, format(date, fullFormatWithTimezone))
        .replace(/%CURRENT_USER_CONSULTANT_EMAIL%/g, `${consultantEmail}`)
        .replace(/%CURRENT_USER_CONSULTANT_ID%/g, `${consultantID}`)
        .replace(/%CURRENT_USER_EMAIL%/g, email)
        .replace(/%CURRENT_USER_FIRSTNAME%/g, firstName)
        .replace(/%CURRENT_USER_ID%/g, `${id}`)
        .replace(/%CURRENT_LOCALE_DATE%/g, format(date, 'yyyy-MM-dd'))
        .replace(/%CURRENT_LOCALE_TIME%/g, format(date, 'HH:mm'))
        .replace(/%CURRENT_USER_LASTNAME%/g, lastName);
}
