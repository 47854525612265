import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Filesystem, Directory, WriteFileOptions } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/angular';

import { getApplicationTypeByFileName } from '../helpers/application-type';
import { toBase64 } from '../helpers/blob-to-base64';

import { FilesystemService } from './filesystem.service';


interface ResultFile { fileName: string; file: Blob | null; };

export const DEFAULT_FILE_DIR = 'act-app-temp-dir';
export const FILE_STORAGE_ROOT = Directory.Documents;

@Injectable()
export class DownloadFileHandleService {

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly androidPermissions: AndroidPermissions,
        private readonly filesystemService: FilesystemService,
        private readonly fileOpener: FileOpener,
    ) { }

    public async handleFileDownload(response: ResultFile): Promise<void> {
        if (!isPlatform('cordova')) {
            this.filesystemService.desktopDownloadFile(response.file as Blob, response.fileName);

            return;
        }

        await this.checkAndroidPermissions();

        const isAppDir = await this.filesystemService.mkDir(DEFAULT_FILE_DIR);

        if (!isAppDir) {
            this.storeWrapper.showToast('Failed to create directory.');

            return;
        }

        const writeOptions = await this.getWriteOptions(response);
        const savedFile = await Filesystem.writeFile(writeOptions);
        const mime = getApplicationTypeByFileName(response.fileName) || '';

        this.fileOpener.open(savedFile.uri, mime)
            .catch((e) => {
                console.log('Error opening file', e);

                this.storeWrapper.showToast('Error opening file.');
            });
    }

    private async checkAndroidPermissions(): Promise<void> {
        if (isPlatform('android')) {
            await this.filesystemService.checkAndRequestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
            await this.filesystemService.checkAndRequestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        }
    }

    private async getWriteOptions(response: ResultFile): Promise<WriteFileOptions> {
        const fileBase64 = await toBase64(response.file as any);
        const path = `${DEFAULT_FILE_DIR}/${response.fileName}`;
        const writeOptions = {
            path,
            data: fileBase64,
            directory: FILE_STORAGE_ROOT,
        };

        return writeOptions;
    }
}
