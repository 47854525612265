import { hrefReplace } from '@actassa/shared';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'jobs-placements-job-notes',
    templateUrl: './job-notes.component.html',
    styleUrls: ['./job-notes.component.scss'],
})
export class JobNotesComponent {

    @Input() public notes: Array<string> | undefined;

    public get hasNotes(): boolean {
        return this.preparedNotes.length > 0;
    }

    public get preparedNotes(): Array<string> {
        const notes = this.notes || [];

        return notes.filter(note => !!note.trim()).map(hrefReplace);
    }

}
