import { Pipe, PipeTransform } from '@angular/core';
import { JobInterface } from '../interfaces/job.interface';

@Pipe({
    name: 'jobListItemTitle',
    pure: true,
})
export class JobListItemTitlePipe implements PipeTransform {
    public transform(value: JobInterface): string {
        return value.jobTitle || '';
    }
}
