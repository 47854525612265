import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { parseDateAsDateInTimezone, InformErrorService } from '@actassa/shared';
import { WeekDay } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { format, formatISO9075, isSameDay, isSameWeek, startOfDay } from 'date-fns';
import { first, isArray, isEmpty, isEqual, sortBy } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, withLatestFrom, shareReplay, filter, switchMap, throttleTime } from 'rxjs/operators';

import { PickTimesheet } from '../+state/app-state/actions/pick-timesheet';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { TIMESHEET_CREATION_ERROR } from '../constants/timesheet.constants';
import { PlacementOvertime, TimesheetApproveConfig } from '../dto/placement.dto.interface';
import { SectionsEnum } from '../enums/sections.enum';
import { formatRecordedHours } from '../helpers/format-record-hours.helper';
import { PlacementInterface } from '../interfaces/placement.interface';
import { RecordTimeConfig } from '../interfaces/record-time-config.interface';
import { TimesheetApproveSourceInterface } from '../interfaces/timesheet-approve-source.interface';
import { TimesheetApprove } from '../interfaces/timesheet-approve.interface';
import { TimesheetHourInterface, TimesheetInterface } from '../interfaces/timesheet.interface';
import { buildNewTimesheet } from '../pages/record-time/build-new-timesheet.helper';
import { DEFAULT_TIMESHEET_APPROVE_CONFIG } from '../pages/timesheet-approve-v2/constants';
import { TimesheetApproveV2Service } from '../pages/timesheet-approve-v2/timesheet-approve-v2.service';

import { TimesheetService } from './time-sheet.service';

const TIMESHEET_SORT_KEY = 'TimesheetID';
const PAGE_KEY = SectionsEnum.PLACEMENT;
const CONFIG_PARAMETER_KEY = 'recordTime';

@Injectable()
export class TimesheetFacadeService {
    @Select(JobsPlacementsState.timesheet$) public readonly timesheet$: Observable<TimesheetInterface | null>;

    @Select(JobsPlacementsState.day$) private _day$: Observable<string>;
    @Select(JobsPlacementsState.placement$) private _placement$: Observable<PlacementInterface>;
    @Select(JobsPlacementsState.placementOvertimes$) private placementOvertimes$: Observable<Array<PlacementOvertime>>;
    @Select(JobsPlacementsState.week$) private _week$: Observable<string>;

    public isClosedTimesheet$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    public placement$: Observable<PlacementInterface>;
    public readonly day$: Observable<Date>;
    public readonly week$: Observable<Date>;
    public recordTimeConfig$: Observable<RecordTimeConfig>;
    public timesheetApproves$: Observable<Array<TimesheetApprove>>;
    public hasApprovePart$: Observable<boolean>;

    private lockedTimesheetStatuses$: Observable<Array<string>>;
    private readonly loadTimesheetsActivator$ = new Subject();
    private readonly createRequestMap = new Map<string, boolean>();

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly informErrorService: InformErrorService,
        private readonly store: Store,
        private readonly timesheetApproveService: TimesheetApproveV2Service,
        private readonly timesheetService: TimesheetService,
    ) {
        this.placement$ = this._placement$.pipe(distinctUntilChanged(isEqual), shareReplay(1));
        this.day$ = this.initSelectedDay$();
        this.week$ = this.initSelectedWeek$();

        this.initRecordTimeConfig$();
        this.initLockedTimesheetStatuses();
        this.initClosedTimesheetCheck();
        this.timesheetApproves$ = this.timesheetApproveService.timesheetApproves$;
        this.hasApprovePart$ = this.timesheetApproveService.hasApprovePart$;
        this.isLoading$ = combineLatest([
            this.timesheetService.isLoading$,
            this.timesheetApproveService.isLoading$,
        ])
            .pipe(
                map(loadingsArray => loadingsArray?.some(Boolean)),
            );
    }

    public getTimesheetApproves(timesheetId: number): void {
        this.timesheetApproveService.getTimesheetApproves(timesheetId);
    }

    public saveTimesheetApprove$(timesheetApprove: TimesheetApproveSourceInterface): Observable<Array<TimesheetApprove>> {
        return this.timesheetApproveService.saveTimesheetApprove$(timesheetApprove);
    }

    public getTotalRecordedHours$(): Observable<Array<string>> {
        return this.timesheet$
            .pipe(
                filter(Boolean),
                withLatestFrom(this.placementOvertimes$),
                map(([timesheet, placementOvertimes]: [TimesheetInterface | null, Array<PlacementOvertime>]) => {
                    if (!isArray(timesheet?.timeSheetHours)) {
                        return placementOvertimes.map(({ overtimeValueName }) => `${overtimeValueName}: `);
                    }

                    return placementOvertimes.map(({ overtimeValueName, placementOvertimeId }) => {
                        const countRecordedHours = timesheet.timeSheetHours
                            .reduce((accumulator, timesheetHour) => {
                                if (timesheetHour?.PLACEMENTOVERTIMEID === placementOvertimeId) {
                                    return accumulator + timesheetHour.HOURSWORKED || 0;
                                }

                                return accumulator;
                            }, 0);

                        return `${overtimeValueName}: ${formatRecordedHours(countRecordedHours * 60)}`;
                    });
                }),
            );
    }

    public getDayTotalRecordedHours$(): Observable<Array<string>> {
        return this.timesheet$
            .pipe(
                withLatestFrom(this.placementOvertimes$, this.day$, this.storeWrapper.timezone$),
                map(([timesheet, placementOvertimes, day, timezone]:
                    [TimesheetInterface | null, Array<PlacementOvertime>, Date, string]) => {
                    if (!isArray(timesheet?.timeSheetHours)) {
                        return placementOvertimes.map(({ overtimeValueName }) => `${overtimeValueName}: `);
                    }

                    return placementOvertimes.map(({ overtimeValueName, placementOvertimeId }) => {
                        const countRecordedHours = timesheet.timeSheetHours
                            .filter((timesheetHour: TimesheetHourInterface) => {
                                const timesheetStart: Date = startOfDay(parseDateAsDateInTimezone(timesheetHour.WORKEDON, timezone));

                                return isSameDay(timesheetStart, day);
                            })
                            .reduce((accumulator, timesheetHour) => {
                                if (timesheetHour?.PLACEMENTOVERTIMEID === placementOvertimeId) {
                                    return accumulator + timesheetHour.HOURSWORKED || 0;
                                }

                                return accumulator;
                            }, 0);

                        return `${overtimeValueName}: ${formatRecordedHours(countRecordedHours * 60)}`;
                    });
                }),
            );
    }

    public getDayTimesheetHours$(): Observable<Array<TimesheetHourInterface>> {
        return this.timesheet$
            .pipe(
                withLatestFrom(this.day$, this.storeWrapper.timezone$),
                map(([timesheet, day, timezone]:
                    [TimesheetInterface | null, Date, string]) => {
                    if (!isArray(timesheet?.timeSheetHours)) {
                        return [];
                    }

                    return timesheet.timeSheetHours
                        .filter((timesheetHour: TimesheetHourInterface) => {
                            const timesheetStart: Date = startOfDay(parseDateAsDateInTimezone(timesheetHour.WORKEDON, timezone));

                            return isSameDay(timesheetStart, day);
                        });
                }),
            );
    }

    public initTimesheets$(throttleTimeout: number): Observable<unknown> {
        return this.loadTimesheetsActivator$
            .pipe(
                throttleTime(throttleTimeout),
                switchMap(() => this.activateTimesheets$()),
            );
    }

    public normalizeTimesheetDateTime(dateTime: string, timeZone: string, outputFormat = 'yyyy-MM-dd HH:mm:ss'): string {
        if (!dateTime) {
            return null;
        }

        return format(parseDateAsDateInTimezone(dateTime, timeZone), outputFormat);
    }

    public loadTimesheets$(placementId: number): Observable<unknown> {
        return this.timesheetService.loadTimesheets$(placementId)
            .pipe(
                switchMap((timesheets: Array<TimesheetInterface>) => this.selectTimesheetStream$(timesheets)),
            );
    }

    public loadTimesheets(): void {
        this.loadTimesheetsActivator$.next(null);
    }

    public getTimesheetApproversConfig$(): Observable<TimesheetApproveConfig> {
        return this.placement$
            .pipe(
                map((placement: PlacementInterface) => {
                    if (isArray(placement.timesheetApprovers)) {
                        const timesheetApproversConfig = placement.
                            timesheetApprovers?.find(timesheetApprover => timesheetApprover.clientId === placement.clientId);

                        return timesheetApproversConfig || DEFAULT_TIMESHEET_APPROVE_CONFIG;
                    }

                    return DEFAULT_TIMESHEET_APPROVE_CONFIG;
                }),
            );
    }

    public initOtherApproverHidden$(): Observable<boolean> {
        return this.getTimesheetApproversConfig$()
            .pipe(
                withLatestFrom(this.hasApprovePart$),
                map(([config, hasApprovePart]: [TimesheetApproveConfig, boolean]) =>
                    hasApprovePart && config.isOtherHidden && isEmpty(config.approvers)),
            );
    }

    private initRecordTimeConfig$(): void {
        this.recordTimeConfig$ = this.storeWrapper.getMenuItemProperty$<RecordTimeConfig>(PAGE_KEY, CONFIG_PARAMETER_KEY)
            .pipe(
                distinctUntilChanged(isEqual),
                shareReplay(1),
            );
    }

    private activateTimesheets$(): Observable<unknown> {
        return this.placement$
            .pipe(
                filter(Boolean),
                distinctUntilChanged(isEqual),
                switchMap(({ placementId }) => this.loadTimesheets$(placementId)),
            );
    }

    private selectTimesheetStream$(timesheets: Array<TimesheetInterface>): Observable<unknown> {
        return combineLatest([this.placement$, this.week$])
            .pipe(
                filter(([placement, week]: [PlacementInterface, Date]) => !!placement && !!week),
                withLatestFrom(this.storeWrapper.timezone$),
                distinctUntilChanged(isEqual),
                switchMap(([[placement, weekStart], timezone]: [[PlacementInterface, Date], string]) => {
                    const actualTimeSheets = timesheets
                        .filter(({ PlacementID }: TimesheetInterface) => PlacementID === placement.placementId)
                        .filter(({ PeriodStarting }: TimesheetInterface) => {
                            const timesheetStart = parseDateAsDateInTimezone(PeriodStarting, timezone);

                            return isSameWeek(weekStart, timesheetStart, { weekStartsOn: WeekDay.Monday });
                        });

                    // INFO: Договоренност: у нас 1 timesheet в неделю и он с понедельника по воскресенье
                    const actualTimesheet: TimesheetInterface = first(sortBy(actualTimeSheets, TIMESHEET_SORT_KEY));

                    const keyOfCreateRequest = `${placement.placementId}_${formatISO9075(weekStart)}`;

                    if (!actualTimesheet) {
                        if (this.createRequestMap.has(keyOfCreateRequest)) {
                            this.storeWrapper.showToast(TIMESHEET_CREATION_ERROR);

                            this.informErrorService.handleErrorInformRequest({
                                message: TIMESHEET_CREATION_ERROR,
                                placement,
                                weekStart,
                                timesheets,
                                timezone,
                                actualTimeSheets,
                                actualTimesheet,
                                keyOfCreateRequest,
                            });

                            throw new Error(TIMESHEET_CREATION_ERROR);
                        }

                        this.createRequestMap.set(keyOfCreateRequest, true);

                        const dto = buildNewTimesheet(placement, weekStart);

                        return this.timesheetService.handleTimesheet$(dto)
                            .pipe(switchMap(() => this.loadTimesheets$(placement.placementId)));
                    }

                    // Костыль, так как остается лоадер
                    this.timesheetService.loadingEnd();

                    return this.store.dispatch(new PickTimesheet(actualTimesheet));
                }),
            );
    }

    private initLockedTimesheetStatuses(): void {
        this.lockedTimesheetStatuses$ = this.recordTimeConfig$
            .pipe(
                distinctUntilChanged(isEqual),
                map((recordTimeConfig: RecordTimeConfig) => {
                    if (isArray(recordTimeConfig?.lockedTimeSheetStatuses)) {
                        return recordTimeConfig.lockedTimeSheetStatuses.filter(Boolean);
                    }

                    return [];
                }),
            );
    }

    private initClosedTimesheetCheck(): void {
        this.isClosedTimesheet$ = this.timesheet$
            .pipe(
                filter(Boolean),
                withLatestFrom(this.lockedTimesheetStatuses$),
                map(([timesheet, lockedTimesheetStatuses]: [TimesheetInterface, Array<string>]) =>
                    lockedTimesheetStatuses.includes(timesheet?.TimesheetApprovalStatus)),
                distinctUntilChanged(),
            );
    }

    private initSelectedDay$(): Observable<Date> {
        return this._day$.pipe(
            distinctUntilChanged(),
            withLatestFrom(this.storeWrapper.timezone$),
            map(([day, timezone]) => parseDateAsDateInTimezone(day, timezone)),
        );
    }

    private initSelectedWeek$(): Observable<Date> {
        return this._week$.pipe(
            distinctUntilChanged(),
            withLatestFrom(this.storeWrapper.timezone$),
            map(([week, timezone]) => parseDateAsDateInTimezone(week, timezone)),
        );
    }
}
