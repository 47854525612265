import { Client, Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, shareReplay, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SelectClientService {
    public loading$!: Observable<boolean>;

    private cache = new Map<number, Observable<Array<Client>>>();
    private _loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly http: HttpClient,
    ) {
        this.loading$ = this._loading$.asObservable().pipe(distinctUntilChanged());
    }

    public getClients$(id: number): Observable<Array<Client>> {
        const url = this.buildUrl();

        if (!this.cache.has(id)) {
            this._loading$.next(true);

            const data$ = this.http.get<Array<Client>>(url)
                .pipe(
                    take(1),
                    catchError((error) => {
                        this.storeWrapper.showToast(error.message);

                        return of([]);
                    }),
                    finalize(() => this._loading$.next(false)),
                    shareReplay(1),
                );

            this.cache.set(id, data$);
        }

        return this.cache.get(id);
    }

    private buildUrl(): string {
        return `${this.environment.apiURL}/api/person/clients`;
    }
}
