import { Injectable } from '@angular/core';
import { App, AppState } from '@capacitor/app';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AppStateActive } from 'state/root-state/actions/app-state-active';
import { AppStateBackground } from 'state/root-state/actions/app-state-background';


@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    constructor() { }

    public init(): void {
        this.appStateActive();

        App.addListener('appStateChange', (state: AppState) => {
            if (state.isActive) {
                this.appStateActive();

                return;
            }

            this.appStateBackground();
        });
    }

    @Dispatch()
    private appStateActive(): AppStateActive {
        return new AppStateActive();
    }

    @Dispatch()
    private appStateBackground(): AppStateBackground {
        return new AppStateBackground();
    }
}
