import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { WeekDay } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { isAfter, isBefore, isSameDay, setDay, startOfDay, startOfWeek } from 'date-fns';
import { first } from 'lodash-es';
import { Observable, combineLatest, Subscription, of } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';

import { LoadPlacementsEvent } from '../../+state/app-state/actions/load-placements-event';
import { PickPlacement } from '../../+state/app-state/actions/pick-placement';
import { SetDay } from '../../+state/app-state/actions/set-day';
import { SetWeek } from '../../+state/app-state/actions/set-week';
import { JobsPlacementsState } from '../../+state/app-state/app.state';
import { MODULE_ROOT_PATH } from '../../constants/routing.constants';
import { RoutesDictionary } from '../../dictionaries/routes.dictionary';
import { PlacementStatus } from '../../enums/placement-status.enum';
import { SortDirectionEnum } from '../../enums/sort-direction.enum';
import { getDateTimePairFromDailyShift } from '../../helpers/mapper.helper';
import { getPlacementSortMethod } from '../../helpers/placements-sort.strategy';
import { PlacementInterface } from '../../interfaces/placement.interface';
import { TimesheetFacadeService } from '../../services/time-sheet-facade.service';

@Component({
    selector: 'jobs-placements-timesheets-widget',
    templateUrl: './timesheets.widget.html',
    styleUrls: ['./timesheets.widget.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetsWidget implements OnInit, OnDestroy {
    @Select(JobsPlacementsState.placements$) public placements$: Observable<Array<PlacementInterface>>;

    @Input() public sortDirection: SortDirectionEnum = SortDirectionEnum.ASC;
    @Input() public statuses: Array<PlacementStatus> = [PlacementStatus.PLACEMENT_CURRENT, PlacementStatus.PLACEMENT_FINISHED];

    @Output() public placementsLength = new EventEmitter<number>();

    public placementsList$: Observable<Array<PlacementInterface>>;

    private timesheetUrl: string;
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly timesheetFacadeService: TimesheetFacadeService,
    ) { }

    public ngOnInit(): void {
        this.initTimesheetUrl();
        this.placementsList$ = this.initPlacementsList();
        this.loadPlacements();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public select(placement: PlacementInterface): void {
        this.pickPlacement(placement);
        this.setWeek(startOfWeek(new Date(), { weekStartsOn: WeekDay.Monday }));
        this.setDay(startOfDay(new Date()));
        this.navigateToTimesheet(placement.placementId);
    }

    private initTimesheetUrl(): void {
        const subscription = this.storeWrapper.baseUrl$
            .subscribe(baseUrl => {
                this.timesheetUrl = `${baseUrl}/${MODULE_ROOT_PATH}/${RoutesDictionary.RECORD_TIME_DAILY}`;
            });

        this.subscriptions.add(subscription);
    }

    private initPlacementsList(): Observable<Array<PlacementInterface>> {
        return combineLatest([
            this.placements$,
            this.storeWrapper.providerId$,
        ]).pipe(
            map(([placements, providerId]: [Array<PlacementInterface>, string]) => {
                const sort = getPlacementSortMethod(this.getSortStatus());
                const today = new Date();
                const actualPlacements = placements
                    .filter((placement) => placement?.providerID === providerId
                        && this.isPlacementHasShiftInDate(placement, today));

                return sort(actualPlacements, this.sortDirection);
            }),
            tap((placements: Array<PlacementInterface>) => {
                this.placementsLength.emit(placements.length);
            }),
        );
    }

    private isPlacementHasShiftInDate(placement: PlacementInterface, referenceDate: Date): boolean {
        const { diary, dailyShifts, firstShiftDateTimePair, lastShiftDateTimePair } = placement;

        if (diary) {
            return diary.some(shift => isSameDay(shift.shiftStartDateTime, referenceDate)
                || isSameDay(shift.shiftEndDateTime, referenceDate));
        }

        if (dailyShifts) {
            return dailyShifts.some(shift => {
                const shiftDate = setDay(referenceDate, shift.dayNumber, { weekStartsOn: WeekDay.Monday });
                const normalizedShiftTimes = getDateTimePairFromDailyShift(shiftDate, shift);

                return (isAfter(referenceDate, firstShiftDateTimePair.startDateTime)
                    || isSameDay(referenceDate, firstShiftDateTimePair.startDateTime))
                    &&
                    (isBefore(referenceDate, lastShiftDateTimePair.endDateTime)
                        || isSameDay(referenceDate, lastShiftDateTimePair.endDateTime))
                    &&
                    (isSameDay(normalizedShiftTimes.startDateTime, referenceDate)
                        || isSameDay(normalizedShiftTimes.endDateTime, referenceDate));
            });
        }

        return false;
    }

    private getSortStatus(): PlacementStatus {
        return first(this.statuses);
    }

    private navigateToTimesheet(id: number): void {
        this.storeWrapper.loadingStart();
        this.timesheetFacadeService.loadTimesheets$(id)
            .pipe(
                tap(() => {
                    this.navigateTimesheet();
                    this.storeWrapper.loadingEnd();
                }),
                catchError(() => {
                    this.storeWrapper.loadingEnd();

                    return of(null);
                }),
                take(1),
            )
            .subscribe();
    }

    @Dispatch()
    public pickPlacement(placement: PlacementInterface | null): PickPlacement {
        return new PickPlacement(placement);
    }

    @Dispatch()
    private loadPlacements(): LoadPlacementsEvent {
        return new LoadPlacementsEvent();
    }

    @Dispatch()
    private navigateTimesheet(): Navigate {
        return new Navigate([this.timesheetUrl]);
    }

    @Dispatch()
    private setWeek(weekStart: Date): SetWeek {
        return new SetWeek(weekStart);
    }

    @Dispatch()
    private setDay(day: Date): SetDay {
        return new SetDay(day);
    }
}
