import { Pipe, PipeTransform } from '@angular/core';
import { JobInterface } from '../interfaces/job.interface';

@Pipe({
    name: 'jobListItemClient',
})
export class JobListItemClientPipe implements PipeTransform {

    public transform(value: JobInterface): string {
        return value?.jobData?.client || '';
    }

}
