import { FcmMessageDataInterface, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { parseDateAsDateInTimezone } from '@actassa/shared';
import { WeekDay } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { startOfWeek } from 'date-fns';
import { isEmpty, isEqual, isString } from 'lodash-es';
import { combineLatest, Observable, of, zip } from 'rxjs';
import {
    take,
    tap,
    mapTo,
    filter,
    switchMap,
    map,
    distinctUntilChanged,
    catchError,
    withLatestFrom,
    delay,
} from 'rxjs/operators';

import { PickCollection } from '../+state/app-state/actions/pick-collection';
import { PickJob } from '../+state/app-state/actions/pick-job';
import { PickPlacement } from '../+state/app-state/actions/pick-placement';
import { PickShift } from '../+state/app-state/actions/pick-shift';
import { SetDay } from '../+state/app-state/actions/set-day';
import { SetShiftConfirmationId } from '../+state/app-state/actions/set-shift-confirmation-id';
import { SetWeek } from '../+state/app-state/actions/set-week';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { CollectionsService } from '../../public_api';
import { MODULE_ROOT_PATH } from '../constants/routing.constants';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { FCMPushDataActions } from '../enums/fcm-push-data-actions.enum';
import { CollectionInterface } from '../interfaces/collection.interface';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';

import { JobsService } from './jobs.service';
import { PlacementsService } from './placements.service';
import { ShiftsService } from './shifts.service';
import { TimesheetFacadeService } from './time-sheet-facade.service';

type pushHelper = (data: FcmMessageDataInterface) => void;

@Injectable()
export class PushHandlerService {
    @Select(JobsPlacementsState.collections$) public collections$: Observable<Array<CollectionInterface>>;
    @Select(JobsPlacementsState.jobs$) public jobs$: Observable<Array<JobInterface>>;
    @Select(JobsPlacementsState.placements$) public placements$: Observable<Array<PlacementInterface>>;
    @Select(JobsPlacementsState.shifts$) public shifts$: Observable<Array<ShiftInterface>>;

    private generatePushHelper: Record<FCMPushDataActions, pushHelper> = {
        [FCMPushDataActions.NEW_COLLECTION]: this.navigateToCollection,
        [FCMPushDataActions.NEW_JOB]: this.navigateToJob,
        [FCMPushDataActions.NEW_PLACEMENT]: this.navigateToPlacement,
        [FCMPushDataActions.NEW_SHIFT]: this.navigateToShift,
        [FCMPushDataActions.REPORT_REMINDER]: this.navigateToReportReminder,
        [FCMPushDataActions.SHIFT_CONFIRMATIONS]: this.navigateToShiftConfirmations,
        [FCMPushDataActions.SHIFT_TIMESHEET]: this.navigateToTimesheet,
        [FCMPushDataActions.SHIFT_TIMESHEET_V3]: this.navigateToTimesheetV3,
    };

    private emptyMessageDictionary: Partial<Record<FCMPushDataActions, string>> = {
        [FCMPushDataActions.NEW_COLLECTION]: 'Sorry, the collection linked to this notification is no longer available.',
        [FCMPushDataActions.NEW_JOB]: 'Sorry, the job linked to this notification is no longer available.',
        [FCMPushDataActions.NEW_PLACEMENT]: 'Sorry, the placement linked to this notification is no longer available.',
        [FCMPushDataActions.NEW_SHIFT]: 'Sorry, the open shift linked to this notification could not be found. Please check Open Shifts for new and updated job opportunities.',
    };

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly collectionsService: CollectionsService,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
        private readonly navigationController: NavController,
        private readonly placementsService: PlacementsService,
        private readonly timeSheetFacadeService: TimesheetFacadeService,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void> {
        return combineLatest([
            this.storeWrapper.notificationData$,
            this.storeWrapper.isAppStateActive$,
            this.storeWrapper.providerId$,
        ])
            .pipe(
                /**
                 * Хак, который исправляет проблему отображения лоадера
                 * (видать не все успевают проинициализироваться)
                 */
                delay(0),
                filter(([data, isAppActive, providerId]: [FcmMessageDataInterface, boolean, string]) =>
                    !isEmpty(data) && isAppActive && this.checkValidProviderId(data, providerId)),
                map(([data]: [FcmMessageDataInterface, boolean, string]) => data),
                distinctUntilChanged(isEqual),
                filter((data: FcmMessageDataInterface) => Boolean(this.generatePushHelper[data.action])),
                // tap(() => this.showLoader()),
                tap(() => this.storeWrapper.loadingStart()),
                switchMap((data: FcmMessageDataInterface) => zip(
                    this.collectionsService.load$(),
                    this.jobsService.load$(),
                    this.placementsService.load$(),
                    this.shiftsService.load$(),
                ).pipe(
                    tap(() => this.storeWrapper.loadingEnd()),
                    tap(() => this.handle(data)),
                    mapTo(undefined),
                )),
                catchError((e) => {
                    this.storeWrapper.loadingEnd();

                    return of();
                }),
            );
    }

    private handle(data: FcmMessageDataInterface): void {
        const handlerFunction: pushHelper = this.generatePushHelper[data.action];

        if (!handlerFunction) {
            return;
        }

        this.storeWrapper.clearNotificationData();

        return handlerFunction.call(this, data);
    }

    private navigateToCollection({ id }: FcmMessageDataInterface): void {
        const key = FCMPushDataActions.NEW_COLLECTION;

        this.collections$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((collections: Array<CollectionInterface>) => {
                const collection = collections.find((j: CollectionInterface) => j.id === +id);

                if (!collection) {
                    this.storeWrapper.showToast(this.emptyMessageDictionary[key]);
                    this.gotoAppPage([RoutesDictionary.OPPORTUNITIES]);

                    return;
                }

                this.pickCollection(collection);
                this.gotoAppPage([RoutesDictionary.COLLECTION]);
            });
    }

    private navigateToJob({ id }: FcmMessageDataInterface): void {
        const key = FCMPushDataActions.NEW_JOB;

        this.jobs$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((jobs: Array<JobInterface>) => {
                const job = jobs.find((j: JobInterface) => j.id === +id);

                if (!job) {
                    this.storeWrapper.showToast(this.emptyMessageDictionary[key]);
                    this.gotoAppPage([RoutesDictionary.OPPORTUNITIES]);

                    return;
                }

                this.pickJob(job);
                this.gotoAppPage([RoutesDictionary.JOB]);
            });
    }

    private navigateToShift({ id }: FcmMessageDataInterface): void {
        const key = FCMPushDataActions.NEW_SHIFT;

        this.shifts$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((shifts: Array<ShiftInterface>) => {
                const shift = shifts.find((j: ShiftInterface) => j.id === +id);

                if (!shift) {
                    this.storeWrapper.showToast(this.emptyMessageDictionary[key]);
                    this.gotoAppPage([RoutesDictionary.OPPORTUNITIES]);

                    return;
                }

                this.pickShift(shift);
                this.gotoAppPage([RoutesDictionary.SHIFT]);
            });
    }

    private navigateToPlacement({ id }: FcmMessageDataInterface): void {
        const key = FCMPushDataActions.NEW_PLACEMENT;

        this.placements$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((placements: Array<PlacementInterface>) => {
                const placement = placements.find((p: PlacementInterface) => p.placementId === +id);

                if (!placement) {
                    this.storeWrapper.showToast(this.emptyMessageDictionary[key]);
                    this.gotoAppPage([RoutesDictionary.ASSIGNMENTS]);

                    return;
                }

                this.pickPlacement(placement);
                this.gotoAppPage([RoutesDictionary.PLACEMENT]);
            });
    }

    private navigateToReportReminder({ placementId }: FcmMessageDataInterface): void {
        this.placements$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((placements: Array<PlacementInterface>) => {
                const placement = placements.find((p: PlacementInterface) => p.placementId === +placementId);

                if (!placement) {
                    this.gotoAppPage([RoutesDictionary.ASSIGNMENTS]);

                    return;
                }

                this.pickPlacement(placement);
                this.gotoAppPage([RoutesDictionary.PLACEMENT]);

                // TODO: Необходима переадресация на Placement
                //this.navigationController.navigateForward(RoutesDictionary.TIMESHEET_RECORD_TIME_SELECTED);
            });
    }

    private navigateToShiftConfirmations({ id, shiftId, shiftDate }: FcmMessageDataInterface): void {
        this.placements$
            .pipe(
                take(1),
                catchError(() => of([])),
            )
            .subscribe((placements: Array<PlacementInterface>) => {
                const placement = placements.find((p: PlacementInterface) => p.placementId === +id);

                if (!placement) {
                    this.gotoAppPage([RoutesDictionary.ASSIGNMENTS]);

                    return;
                }

                this.pickPlacement(placement);
                this.setShiftConfirmationId(shiftId, shiftDate);
                this.gotoAppPage([RoutesDictionary.SHIFT_CONFIRMATIONS]);
            });
    }

    private navigateToTimesheet({ id, shiftDate }: FcmMessageDataInterface): void {
        this.storeWrapper.loadingStart();
        this.placements$
            .pipe(
                withLatestFrom(this.storeWrapper.timezone$),
                switchMap(([placements, timezone]: [Array<PlacementInterface>, string]) => {
                    const placement = placements.find((p: PlacementInterface) => p.placementId === +id);

                    if (!placement) {
                        throw new Error();
                    }

                    this.pickPlacement(placement);

                    const day = parseDateAsDateInTimezone(shiftDate, timezone);
                    const week = startOfWeek(day, { weekStartsOn: WeekDay.Monday });

                    this.setWeek(week);
                    this.setDay(day);

                    return this.timeSheetFacadeService.loadTimesheets$(+id);
                }),
                tap(() => {
                    this.gotoAppPage([RoutesDictionary.RECORD_TIME_DAILY]);
                    this.storeWrapper.loadingEnd();
                }),
                catchError(() => {
                    this.gotoAppPage([RoutesDictionary.ASSIGNMENTS]);
                    this.storeWrapper.loadingEnd();

                    return of(null);
                }),
                take(1),
            )
            .subscribe();
    }

    private navigateToTimesheetV3({ id, shiftDate }: FcmMessageDataInterface): void {
        this.gotoAppPage(['v3', RoutesDictionary.TIMESHEETS], { queryParams: { date: shiftDate, placementId: id } });
    }

    private gotoAppPage(path: Array<string>, options?: any): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot([baseUrl, MODULE_ROOT_PATH, ...path], options);
            });
    }

    private checkValidProviderId(data: FcmMessageDataInterface, providerId: string): boolean {
        if (!data?.providerId) {
            return true;
        }

        const condition = data.providerId === providerId;

        if (!condition) {
            const message = 'This notification is for another app.';

            this.storeWrapper.showToast(message);
        }

        return condition;
    }

    @Dispatch()
    private pickCollection(collection: CollectionInterface | null): PickCollection {
        return new PickCollection(collection);
    }

    @Dispatch()
    private pickJob(job: JobInterface | null): PickJob {
        return new PickJob(job);
    }

    @Dispatch()
    private pickShift(shift: ShiftInterface | null): PickShift {
        return new PickShift(shift);
    }

    @Dispatch()
    private pickPlacement(placement: PlacementInterface | null): PickPlacement {
        return new PickPlacement(placement);
    }

    @Dispatch()
    private setShiftConfirmationId(shiftId: string | null, shiftDate: string | null): SetShiftConfirmationId {
        return new SetShiftConfirmationId(shiftId, shiftDate);
    }

    @Dispatch()
    private setWeek(weekStart: Date): SetWeek {
        return new SetWeek(weekStart);
    }

    @Dispatch()
    private setDay(day: Date): SetDay {
        return new SetDay(day);
    }
}
