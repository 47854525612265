import { CandidateSectionsEnum } from '../enums/candidate-sections.enum';

export const CandidateRoutesDictionary: Record<CandidateSectionsEnum, string> = {
    [CandidateSectionsEnum.AVAILABILITY]: 'availability',
    [CandidateSectionsEnum.COMPLIANCE]: 'compliance',
    [CandidateSectionsEnum.DATA_COLLECTION]: 'data-collection',
    [CandidateSectionsEnum.DOCUMENTS]: 'documents',
    [CandidateSectionsEnum.HELP]: 'help',
    [CandidateSectionsEnum.HOME]: 'home',
    [CandidateSectionsEnum.JOBS_PLACEMENTS]: 'jobs-placements',
    [CandidateSectionsEnum.LOGOUT]: '/logout',
    [CandidateSectionsEnum.MESSAGING]: 'messaging',
    [CandidateSectionsEnum.SELECT_APPLICATION]: '/select-app',
    [CandidateSectionsEnum.SETTINGS]: 'settings',
};
