import { ENVIRONMENT_TOKEN, Environment, STORE_WRAPPER_TOKEN, StoreWrapperInterface } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, first, map, tap } from 'rxjs/operators';

import { DEFAULT_FILTER_CONFIG } from '../constants/filter.const';
import { FilterConfig } from '../interfaces/filter-config.interface';

@Injectable({
    providedIn: 'root',
})
export class FiltersService {
    public get loading$(): Observable<boolean> {
        return combineLatest([this._loadingDataGet$, this._loadingDataSave$])
            .pipe(
                map((loaders: [boolean, boolean]) => loaders.some(Boolean)),
                distinctUntilChanged(),
            );
    }

    public get filters$(): Observable<FilterConfig> {
        return this._cache$
            .pipe(
                distinctUntilChanged(isEqual),
            );
    }

    private _loadingDataGet$ = new BehaviorSubject<boolean>(false);
    private _loadingDataSave$ = new BehaviorSubject<boolean>(false);
    private _cache$ = new BehaviorSubject<FilterConfig>(DEFAULT_FILTER_CONFIG);

    constructor(
        private readonly http: HttpClient,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
    ) { }

    public getList$(): Observable<FilterConfig> {
        this._loadingDataGet$.next(true);

        return this.http.get<FilterConfig>(`${this.environment.apiURL}/messaging/filters`)
            .pipe(
                first(),
                tap((config: FilterConfig | null) => this._cache$.next(config || DEFAULT_FILTER_CONFIG)),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of(DEFAULT_FILTER_CONFIG);
                }),
                finalize(() => this._loadingDataGet$.next(false)),
            );
    }

    public save$(dto: FilterConfig): Observable<FilterConfig> {
        this._loadingDataSave$.next(true);

        return this.http.post<FilterConfig>(`${this.environment.apiURL}/messaging/filters`, dto)
            .pipe(
                first(),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of(DEFAULT_FILTER_CONFIG);
                }),
                finalize(() => this._loadingDataSave$.next(false)),
            );
    }
}
