import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

import { JobOfferType } from '../../enums/job-offer-type.enum';
import { JobStatus } from '../../enums/job-status.enum';
import { UserViewStatus } from '../../enums/user-view-status.enum';
import { WithOfferTypeInterface } from '../../interfaces/with-offer-type.interface';
import { WithJobStatusInterface } from '../../interfaces/with-status.interface';
import { WithUserViewStatusInterface } from '../../interfaces/with-user-view-status.interface';
import { JobLikeType } from '../../types/job-item.type';

@Component({
    selector: 'jobs-placements-list-item-job',
    templateUrl: './list-item-job.component.html',
    styleUrls: ['./list-item-job.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemJobComponent {
    @Input() public highlightInterestedInSomeShiftsJob: boolean;
    @Input() public isShowPayRate: boolean;
    @Input() public item: JobLikeType;

    @Output() public selectEvent = new EventEmitter<JobLikeType>();

    public select(item: JobLikeType): void {
        this.selectEvent.emit(item);
    }

    public showStar(item: WithJobStatusInterface): boolean {
        return item.status === JobStatus.INTERESTED_SOME_SHIFTS && this.highlightInterestedInSomeShiftsJob;
    }

    public isHighlightedItem(item: WithUserViewStatusInterface): boolean {
        return item.userViewStatus !== UserViewStatus.READ;
    }

    public isCollectionItem(item: WithOfferTypeInterface): boolean {
        return item.offerType === JobOfferType.COLLECTION;
    }

    public showPayRate(item: JobLikeType): boolean {
        return this.isShowPayRate && Boolean(item.jobData?.payRateAmount);
    }
}
