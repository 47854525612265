import { Environment, ENVIRONMENT_TOKEN, PlatformEnum } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { defer, from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppVersionService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
    ) { }

    public getAppVersion$(): Observable<string> {
        return defer(() => Device.getInfo()
            .then(async (deviceInfo: DeviceInfo) => {
                if (deviceInfo.platform !== PlatformEnum.WEB) {
                    const appInfo: AppInfo = await App.getInfo();

                    return appInfo.version;
                }

                return this.environment.appVersion;
            }));
    }
}
