import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { cloneDeep } from 'lodash-es';

import { SaveFilterAction } from '../+state/app-state/actions/save-filter.action';
import { SavedFilterListComponent } from '../components/saved-filter-list/saved-filter-list.component';
import { SavedFilterInterface } from '../interfaces/saved-filter.interface';

@Injectable()
export class SavedFiltersModalService {
    constructor(
        private readonly alertController: AlertController,
        private readonly modalController: ModalController,
    ) { }

    public async openSavedFiltersModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: SavedFilterListComponent,
            cssClass: 'saved-filters',
        });

        modal.onWillDismiss()
            .then(() => { });

        return await modal.present();
    }

    public async openSaveFilterModal(filter: SavedFilterInterface): Promise<void> {
        const modal = await this.alertController.create({
            header: 'Save Filter',
            inputs: [
                {
                    name: 'filterName',
                    type: 'text',
                    value: filter.name || '',
                },
            ],
            buttons: [
                {
                    text: 'Save',
                    handler: ({ filterName }): void => {

                        if (filter.name !== filterName) {
                            filter = cloneDeep(filter);
                            filter.name = filterName;
                            filter.id = Date.now();
                        }

                        this.saveFilter(filter);
                    },
                }, {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
            ],
        });

        await modal.present();
    }

    @Dispatch()
    private saveFilter(filter: SavedFilterInterface): SaveFilterAction {
        return new SaveFilterAction(filter);
    }
}
