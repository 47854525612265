import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'actassa-detail-list-item',
    templateUrl: './detail-list-item.component.html',
    styleUrls: ['./detail-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailListItemComponent {
    @Input() public title!: string | undefined;
    @Input() public value!: string | undefined;
    @Input() public values!: Array<string> | undefined;
    @Input() public htmlValue!: string | undefined;
    @Input() public htmlValues!: Array<string> | undefined;
    @Input() public set titleSize(val: number) {
        this._titleSize = val;
    }

    public totalSize = 12;

    private _titleSize = 4;

    public get titleSize(): number {
        return this._titleSize || 0;
    }

    public get valueSize(): number {
        return this.totalSize - this._titleSize;
    }
}
