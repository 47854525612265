import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormSkeletonComponent } from './form-skeleton.component';

@NgModule({
    declarations: [FormSkeletonComponent],
    imports: [CommonModule, IonicModule],
    exports: [FormSkeletonComponent],
})
export class FormSkeletonModule {}
