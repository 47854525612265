import { DestroyService } from '@actassa/shared';
import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
} from '@angular/core';
import { switchMap, takeUntil } from 'rxjs';

import { TimesheetApproveV2Service } from './pages/timesheet-approve-v2/timesheet-approve-v2.service';
import { SavedFiltersEffectsService } from './services/saved-filters.effects.service';
import { TimesheetService } from './services/time-sheet.service';

@Component({
    selector: 'jobs-placements-jobs-placements',
    templateUrl: 'jobs-placements.component.html',
    styleUrls: ['jobs-placements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DestroyService,
    ],
})
export class JobsPlacementsComponent implements OnInit {
    constructor(
        private readonly onDestroy$: DestroyService,
        private readonly timesheetService: TimesheetService,
        private readonly savedFiltersActionsService: SavedFiltersEffectsService,
        private readonly timesheetApproveService: TimesheetApproveV2Service,
    ) { }

    public ngOnInit(): void {
        this.timesheetService.init$()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
        this.savedFiltersActionsService.init$()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
        this.timesheetApproveService.getApproveDataThrottleTimeout$()
            .pipe(
                switchMap(timeout => this.timesheetApproveService.loadTimesheetApproves$(timeout)),
                takeUntil(this.onDestroy$),
            )
            .subscribe();
    }
}
