import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ImagePreviewComponent } from './image-preview.component';

@NgModule({
    declarations: [ImagePreviewComponent],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [ImagePreviewComponent],
})
export class ImagePreviewModule { }
