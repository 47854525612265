import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'jobListItemSubTitle',
    pure: true,
})
export class JobListItemSubTitlePipe implements PipeTransform {
    public transform(value: any): string {
        return value.jobSubTitle || '';
    }
}
