import { ApplicationsEnum, AUTH_SERVICE_TOKEN, PlatformEnum } from '@actassa/api';
import { UserInterface } from '@actassa/api';
import { DestroyService } from '@actassa/shared';
import {
    Component,
    OnInit,
    ViewChildren,
    QueryList,
    AfterViewInit,
    ChangeDetectionStrategy,
    Inject,
} from '@angular/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';
import {
    Platform,
    IonRouterOutlet,
} from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, merge, takeUntil } from 'rxjs';
import { AlertService } from 'services/alert.service';
import { AppStateService } from 'services/app-state.service';
import { AuthService } from 'services/auth.service';
import { BackButtonService } from 'services/back-button.service';
import { LoadingService } from 'services/loading.service';
import { PushHandlerService } from 'services/push-handler.service';
import { ToastService } from 'services/toast.service';
import { SetDeviceID } from 'state/root-state/actions/set-device-id';
import { RootState } from 'state/root-state/root.state';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DestroyService,
    ],
})
export class AppComponent implements OnInit, AfterViewInit {
    @Select(RootState.isAuthenticated$) public isAuthenticated$: Observable<boolean>;
    @Select(RootState.isAppStateActive$) public isAppStateActive$: Observable<boolean>;
    @Select(RootState.user$) public user$: Observable<UserInterface>;
    @Select(RootState.app$) public app$: Observable<ApplicationsEnum>;

    @ViewChildren(IonRouterOutlet) public routerOutlets: QueryList<IonRouterOutlet>;

    constructor(
        @Inject(AUTH_SERVICE_TOKEN) private readonly auth: AuthService,
        private readonly alertService: AlertService,
        private readonly appStateService: AppStateService,
        private readonly backButtonService: BackButtonService,
        private readonly loadingService: LoadingService,
        private readonly onDestroy$: DestroyService,
        private readonly platform: Platform,
        private readonly pushHandlerService: PushHandlerService,
        private readonly toastService: ToastService,
    ) { }

    public ngOnInit(): void {
        this.initializeApp();
        this.appStateService.init();
        this.pushHandlerService.init();

        merge(
            this.auth.init(),
            this.loadingService.init(),
            this.toastService.init(),
            this.alertService.init(),
            this.toastService.init(),
        )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
    }

    public ngAfterViewInit(): void {
        // Initialize BackButton Eevent.
        this.backButtonService.backButtonEvent(this.routerOutlets);
    }

    private initializeApp(): void {
        Promise.all([Device.getInfo(), Device.getId()])
            .then(([info, deviceId]: [DeviceInfo, DeviceId]) => {
                const { platform } = info;

                this.setDeviceID(deviceId.identifier);

                if (platform !== PlatformEnum.WEB) {
                    this.platform.ready().then(() => {
                        StatusBar.setStyle({ style: StatusBarStyle.Light });
                        // StatusBar.setOverlaysWebView({
                        //     overlay: true
                        // });
                        SplashScreen.hide();
                    });

                }
            });
    }

    @Dispatch()
    private setDeviceID(deviceID: string): SetDeviceID {
        return new SetDeviceID(deviceID);
    }
}
