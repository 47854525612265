import { Environment } from '@actassa/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export abstract class AbstractDataLoader {
    public loader$: Observable<boolean>;

    protected readonly _loader$ = new BehaviorSubject<boolean>(false);

    constructor(
        protected readonly environment: Environment,
    ) {
        this.loader$ = this._loader$.asObservable();
    }

    protected buildUrl(slug: string): string {
        return `${this.environment.apiURL}/${slug}`;
    }

    protected startLoading(): void {
        this._loader$.next(true);
    }

    protected stopLoading(): void {
        this._loader$.next(false);
    }

    public abstract load$(data: any): Observable<any>;
}
