<ng-container *ngIf="hasDiary">
    <section *ngIf="disabled" class="ion-margin-top">
        <div>
            <ion-text color="primary">Job calendar:</ion-text>
        </div>
        <ul>
            <li *ngFor="let shift of diary">
                <ion-text color="dark">
                    {{ getShiftDescription(shift) }}
                </ion-text>
            </li>
        </ul>
    </section>

    <ion-list *ngIf="!disabled" class="ion-no-margin ion-no-padding ion-margin-top">
        <ion-item class="ion-no-margin ion-no-padding action-buttons" lines="none">
            <ion-button (click)="selectAll()" class="ion-no-margin" color="primary" fill="outline" size="small">Select All </ion-button>
            <ion-button (click)="clearAll()" class="ion-no-margin" color="primary" fill="outline" size="small">Clear All </ion-button>
        </ion-item>
        <ion-item *ngFor="let shift of diary" class="ion-no-margin ion-no-padding" lines="none">
            <ion-checkbox
                [checked]="isShiftChecked(shift)"
                (ionChange)="handleShiftStatusChanged(shift)"
                class="cherry-checkbox"
                mode="md"
                justify="start"
                labelPlacement="end">
                {{ getShiftDescription(shift) }}
            </ion-checkbox>
        </ion-item>
    </ion-list>
</ng-container>
