/**
 * Microsoft Office MIME types:
   https://www.iana.org/assignments/media-types/media-types.xhtml#application
 */

import { AcceptFileType } from '../enums/file-type.enum';

const mimeTypeMap = {
    [AcceptFileType.DOC as string]: 'application/msword',
    [AcceptFileType.DOCX as string]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    [AcceptFileType.JPG as string]: 'image/jpeg',
    [AcceptFileType.JPEG as string]: 'image/jpeg',
    [AcceptFileType.PDF as string]: 'application/pdf',
    [AcceptFileType.PNG as string]: 'image/png',
    [AcceptFileType.RTF as string]: 'application/rtf',
};

export function getApplicationTypeByFileName(fileName: string = ''): string | undefined {
    const fileNameParts: Array<string> = fileName?.split('.');

    if (!fileNameParts) {
        return undefined;
    }

    const extIndex = fileNameParts.length - 1;

    return mimeTypeMap[`.${fileNameParts[extIndex]}`];
}
