import { SectionsEnum } from '../enums/sections.enum';

export const RoutesDictionary: Record<SectionsEnum, string> = {
    [SectionsEnum.APPLICATIONS]: 'applications',
    [SectionsEnum.ASSIGNMENTS]: 'assignments',
    [SectionsEnum.COLLECTION]: 'collection',
    [SectionsEnum.DECLINED_OPPORTUNITIES]: 'declined-opportunities',
    [SectionsEnum.FENCE_ALERT]: 'fence-alert',
    [SectionsEnum.FINISHED_ASSIGNMENTS]: 'finished-assignments',
    [SectionsEnum.GEOFENCE]: 'geofence',
    [SectionsEnum.HISTORY]: 'history',
    [SectionsEnum.JOB]: 'job',
    [SectionsEnum.OPPORTUNITIES]: 'opportunities',
    [SectionsEnum.PLACEMENT]: 'placement',
    [SectionsEnum.RECORD_EXPENSE]: 'record-expense',
    [SectionsEnum.RECORD_TIME_DAILY]: 'record-time-daily',
    [SectionsEnum.RECORD_TIME_ENTITY]: 'record-time-entity',
    [SectionsEnum.RECORD_TIME]: 'record-time',
    [SectionsEnum.SHIFT_CONFIRMATIONS]: 'shift-confirmations',
    [SectionsEnum.SHIFT]: 'shift',
    [SectionsEnum.SUCCESSFUL_APPLICATIONS]: 'successful-applications',
    [SectionsEnum.TIMESHEET_APPROVE_V2]: 'approve',
    [SectionsEnum.TIMESHEET_RECORD_TIME_SELECTED]: 'record-time-selected',
    [SectionsEnum.TIMESHEET_VIEW_TIMESHEET]: 'view-timesheet',
    [SectionsEnum.TIMESHEET]: 'timesheet',
    [SectionsEnum.TIMESHEETS]: 'timesheets',
    [SectionsEnum.TIMESHEET_HOURS]: 'timesheet/:timesheetId/hours',
    [SectionsEnum.TIMESHEET_APPROVE]: 'timesheet/:timesheetId/approve',
    [SectionsEnum.TIMESHEET_HOUR]: 'timesheet/:timesheetId/hour/:timesheetHourId',
    [SectionsEnum.TIMESHEET_HOUR_ADD]: 'timesheet/:timesheetId/hour',
    [SectionsEnum.UNANSWERED_APPLICATIONS]: 'unanswered-applications',
    [SectionsEnum.UNANSWERED_OPPORTUNITIES]: 'unanswered-opportunities',
    [SectionsEnum.UNSUCCESSFUL_APPLICATIONS]: 'unsuccessful-applications',
};
