import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Filesystem, Directory } from '@capacitor/filesystem';


@Injectable()
export class FilesystemService {
    constructor(private androidPermissions: AndroidPermissions) { }

    public desktopDownloadFile(file: Blob, filename: string): void {
        const url = window.URL.createObjectURL(file);
        const link = window.document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        window.document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public async mkDir(path = '', directory = Directory.Documents): Promise<boolean> {
        let isAppDir = await Filesystem.readdir({
            path,
            directory,
        })
            .then(() => true)
            .catch(() => false);

        if (!isAppDir) {
            isAppDir = await Filesystem.mkdir({
                path,
                directory,
            })
                .then(() => true)
                .catch(() => false);
        }

        return isAppDir;
    }

    public checkAndRequestPermission(permission: string): Promise<boolean> {
        return this.androidPermissions.checkPermission(permission).then(
            (result) => result.hasPermission,
            () =>
                this.androidPermissions.requestPermission(permission).then(
                    (result) => result.hasPermission,
                    () => false,
                ),
        );
    }
}
