import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unreadMessages',
})
export class UnreadMessagesPipe implements PipeTransform {
    public transform(value: number, format?: string): string {
        if (!value) {
            return '';
        }

        if (value === -1) {
            if (format === 'short') {
                return '(1 unread)';
            }

            return '1 message you sent not read';
        }

        if (value < -1) {
            return `${-value} messages you sent not read`;
        }

        if (value === 1) {
            if (format === 'short') {
                return '(1 new)';
            }

            return '1 new message';
        }

        if (format === 'short') {
            return `(${value} new)`;
        }

        return `${value} new messages`;
    }
}
