import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PlacementsWidget } from './placements.widget';

const routes: Routes = [
    {
        path: '',
        component: PlacementsWidget,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlacementsWidgetRoutingModule { }
