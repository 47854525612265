import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { UserViewStatus } from '../../enums/user-view-status.enum';
import { WithUserViewStatusInterface } from '../../interfaces/with-user-view-status.interface';

enum StatusLabelValue {
    EMPTY = '',
    NEW = 'new',
    UPDATED = 'upd',
};

@Component({
    selector: 'jobs-placements-list-item-status',
    templateUrl: './list-item-status.component.html',
    styleUrls: ['./list-item-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemStatusComponent {
    @Input() public item: WithUserViewStatusInterface;

    public getStatusString(item: WithUserViewStatusInterface): string {
        if (item?.userViewStatus === UserViewStatus.NEW) {
            return StatusLabelValue.NEW;
        }

        if (item?.userViewStatus === UserViewStatus.UPDATED) {
            return StatusLabelValue.UPDATED;
        }

        return StatusLabelValue.EMPTY;
    }
}
