import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { format, parse } from 'date-fns';
import { isArray } from 'lodash-es';

import { DailyShiftInterface } from '../../interfaces/daily-shift.interface';

@Component({
    selector: 'jobs-placements-daily-shifts',
    templateUrl: './daily-shifts.component.html',
    styleUrls: ['./daily-shifts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyShiftsComponent {
    @Input() public dailyShifts: Array<DailyShiftInterface> | undefined;

    public get hasDailyShift(): boolean {
        return isArray(this.dailyShifts);
    }

    public getShiftDescription(shift: DailyShiftInterface): string {
        const { day: shiftDay, shiftStartTime, shiftEndTime } = shift;

        const dayObj = parse(shiftDay, 'iiii', new Date());
        const dayStr = format(dayObj, 'iii');
        const shiftStartTimeStr = format(shiftStartTime, 'h:mm aaa');
        const shiftEndTimeStr = format(shiftEndTime, 'h:mm aaa');

        if (shiftStartTimeStr === shiftEndTimeStr) {
            return `${dayStr}: ${shiftStartTimeStr} - TBD`;
        }

        return `${dayStr}: ${shiftStartTimeStr} - ${shiftEndTimeStr}`;
    }
}
