import {
    ApplicationsEnum,
    AuthInterface,
    Environment,
    ENVIRONMENT_TOKEN,
    PlatformEnum,
    SendDeviceTokenInterface,
    UserInterface,
} from '@actassa/api';
import { genericRetryStrategy, NetworkStatusService, VersionService } from '@actassa/shared';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { MenuController } from '@ionic/angular';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap, switchMap, finalize, delay, filter, retryWhen } from 'rxjs/operators';
import { ClearAwaitingDeviceToken } from 'state/root-state/actions/clear-awaiting-device-token';
import { ForgotPassword } from 'state/root-state/actions/forgot-password';
import { LoadingEnd } from 'state/root-state/actions/loading-end';
import { LoadingStart } from 'state/root-state/actions/loading-start';
import { Login } from 'state/root-state/actions/login';
import { LoginFailure } from 'state/root-state/actions/login-failure';
import { LoginSuccess } from 'state/root-state/actions/login-success';
import { Logout } from 'state/root-state/actions/logout';
import { SelectRole } from 'state/root-state/actions/select-role';
import { SendDeviceToken } from 'state/root-state/actions/send-device-token';
import { SetAwaitingDeviceToken } from 'state/root-state/actions/set-awaiting-device-token';
import { ShowToast } from 'state/root-state/actions/show-toast';

@Injectable()
export class AuthService {
    private appId = '';
    private appVersion = '';
    private platform = 'web';

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly menu: MenuController,
        private readonly networkStatusService: NetworkStatusService,
        private readonly versionService: VersionService,
    ) { }

    public init(): Observable<unknown> {
        Device.getInfo()
            .then(async (deviceInfo: DeviceInfo) => {
                if (deviceInfo.platform !== PlatformEnum.WEB) {
                    const appInfo = await App.getInfo();

                    this.appId = appInfo.id;
                    this.appVersion = appInfo.version;
                    this.platform = deviceInfo.platform;

                    return;
                }

                this.appId = this.environment.appId;
                this.appVersion = this.environment.appVersion;
            });

        return merge(
            this.actions$
                .pipe(
                    ofActionSuccessful(Logout),
                    tap(() => {
                        this.menu.close();
                        this.menu.enable(false);
                        this.menu.swipeGesture(false);

                        this.gotoLogin();
                    }),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(Login),
                    switchMap(({ payload }: Login) => this.login(payload)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(LoginFailure),
                    tap(({ message }: LoginFailure) => this.presentToast(message)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(ForgotPassword),
                    switchMap(({ email }: ForgotPassword) => this.forgotPassword(email)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SelectRole),
                    map(({ config }: SelectRole) => config.role),
                    filter((app: ApplicationsEnum) => Boolean(app)),
                    delay(0),
                    switchMap((app: ApplicationsEnum) => this.setServerActiveApplication$(app)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SendDeviceToken),
                    switchMap(({ payload }: SendDeviceToken) => this.sendDeviceToken$(payload)),
                    switchMap(() => this.versionService.checkAppVersion$(this.appId, this.appVersion, this.platform)),
                ),
        );
    }

    public changePassword(data): Observable<unknown> {
        if (data) {
            this.loadingStart();

            return this.http.post(`${this.environment.apiURL}/v2/auth/change-password`, data)
                .pipe(
                    take(1),
                    catchError((error: HttpErrorResponse) => of()),
                    finalize(() => this.loadingEnd()),
                );
        }

        return of();
    }

    private login(auth: AuthInterface): Observable<unknown> {
        if (auth.appPassword) {
            this.loadingStart();

            return this.http.post(`${this.environment.apiURL}/v2/auth/login`, auth)
                .pipe(
                    // take(1),
                    map((response: any) => {
                        if (response.status === 'ok') {
                            this.loginSuccess({ ...response.data });

                            return;
                        }

                        this.loginFailure(response.message);
                        this.loadingEnd();

                        return;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.loginFailure(error.message);
                        this.loadingEnd();

                        return of();
                    }),
                );
        }

        return of();
    }

    private forgotPassword(email: string): Observable<unknown> {
        if (email) {
            return this.http.post(`${this.environment.apiURL}/v2/auth/forgot-password`, { email })
                .pipe(
                    catchError((error: HttpErrorResponse) => of()),
                );
        }

        return of();
    }

    private setServerActiveApplication$(application: ApplicationsEnum): Observable<any> {
        return this.http.post(`${this.environment.apiURL}/v2/auth/set-active-app`, { application })
            .pipe(take(1));
    }

    private sendDeviceToken$(data: SendDeviceTokenInterface): Observable<void> {
        return this.networkStatusService.isNetworkConnected$
            .pipe(
                take(1),
                switchMap((isConnected: boolean) => isConnected
                    ? this.sendDeviceTokenOnline$(data)
                    : this.sendDeviceTokenOffline$(data)),
            );
    }

    private sendDeviceTokenOnline$(data: SendDeviceTokenInterface): Observable<any> {
        return this.http.post(`${this.environment.apiURL}/v2/auth/device-token`, {
            ...data,
            appId: this.appId,
            appVersion: this.appVersion,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
            .pipe(
                tap(() => this.clearAwaitingDeviceToken()),
                retryWhen(genericRetryStrategy()),
                catchError((error: HttpErrorResponse) => {
                    this.presentToast(error.message);

                    return throwError(error);
                }),
            );
    }

    private sendDeviceTokenOffline$(data: SendDeviceTokenInterface): Observable<any> {
        this.setAwaitingDeviceToken(data);

        return of();
    }

    @Dispatch()
    private loadingStart(): LoadingStart {
        return new LoadingStart();
    }

    @Dispatch()
    private loadingEnd(): LoadingEnd {
        return new LoadingEnd();
    }

    @Dispatch()
    private gotoLogin(): Navigate {
        return new Navigate([RoutesDictionary.LOGIN]);
    }

    @Dispatch()
    private loginSuccess(payload: UserInterface): LoginSuccess {
        return new LoginSuccess(payload);
    }

    @Dispatch()
    private loginFailure(message: string): LoginFailure {
        return new LoginFailure(message);
    }

    @Dispatch()
    private presentToast(message: string): ShowToast {
        return new ShowToast(message);
    }

    @Dispatch()
    private setAwaitingDeviceToken(payload: SendDeviceTokenInterface): SetAwaitingDeviceToken {
        return new SetAwaitingDeviceToken(payload);
    }

    @Dispatch()
    private clearAwaitingDeviceToken(): ClearAwaitingDeviceToken {
        return new ClearAwaitingDeviceToken();
    }
}
