import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'jobs-placements-list-loading',
    templateUrl: './list-loading.component.html',
    styleUrls: ['./list-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListLoadingComponent {
    @Input() public isLoading = false;
}
