import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { bytesToMegaBytes } from '../helpers/byteToMb.helper';

export function fileSizeValidator(allowedFileSize: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const isCorrectSize = allowedFileSize && bytesToMegaBytes(value[0].size) < allowedFileSize;

        return !isCorrectSize ? { fileSize: `The file must not be larger than ${allowedFileSize} megabytes` } : null;
    };
}
