import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { MenuItemsService } from '../services/menu-items.service';
import { STORE_WRAPPER_TOKEN, StoreWrapperInterface } from '@actassa/api';
import { cloneDeep, isEmpty, } from 'lodash-es';

export const canActivateMenuItems: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
    const menuItemsService = inject(MenuItemsService);
    const router = inject(Router);
    const storeWrapper = inject<StoreWrapperInterface>(STORE_WRAPPER_TOKEN);
    const appSettings$ = storeWrapper.appSettings$
        .pipe(
            filter(settings => !isEmpty(settings)),
            map(settings => cloneDeep(Object.values(settings))),
        );

    return menuItemsService.get(appSettings$)
        .pipe(
            map(menuItems => {
                const menuItem = menuItems
                    .find(({ key }) => key && key === route.data?.key);

                return menuItem?.isActive || router.parseUrl('/')
            })
        );
};
