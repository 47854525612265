import { formatISO9075, nextSunday } from 'date-fns';
import { TimesheetInsertDto } from '../../dto/timesheet-insert.dto';
import { ActionType } from '../../enums/action.enum';
import { PlacementInterface } from '../../interfaces/placement.interface';

export function buildNewTimesheet(placement: PlacementInterface, weekStart: Date): TimesheetInsertDto {
    const weekEnd: Date = nextSunday(weekStart);

    return {
        action: ActionType.INSERT,
        contactorInvoiceNo: '',
        documentId: null,
        invoicedByContractor: 'N',
        issued: 'N',
        periodEnding: `${formatISO9075(weekEnd, { representation: 'date' })} 00:00:00`,
        periodStarting: `${formatISO9075(weekStart, { representation: 'date' })} 00:00:00`,
        placementId: placement.placementId,
        readyForBilling: 'N',
        timesheetApprovalStatusId: null,
    };
}
