import { nbspReplace } from '@actassa/shared';
import {
    format, isSameDay,
} from 'date-fns';
import { isDate } from 'lodash-es';
import { JobOfferType } from '../enums/job-offer-type.enum';
import { JobDataInterface } from '../interfaces/job-data.interface';
import { PlacementInterface } from '../interfaces/placement.interface';

export function isOneShiftJobOrPlacement(jobDataOrPlacement: JobDataInterface | PlacementInterface): boolean {
    return isSameDay(jobDataOrPlacement.firstShiftDateTimePair?.startDateTime, jobDataOrPlacement.lastShiftDateTimePair?.startDateTime)
        && isSameDay(jobDataOrPlacement.firstShiftDateTimePair?.endDateTime, jobDataOrPlacement.lastShiftDateTimePair?.endDateTime);
}

export function formatDate(dateTime: Date): string {
    return nbspReplace(isDate(dateTime) ? format(dateTime, 'iii d MMM yyyy') : '');
}

function formatTime(dateTime: Date): string {
    return nbspReplace(isDate(dateTime) ? format(dateTime, 'h:mm aaa') : '');
}

function formatDateShort(dateTime: Date): string {
    return nbspReplace(isDate(dateTime) ? format(dateTime, 'MMM d yyyy') : '');
}

export function formatShiftDateTime(startDateTime: Date, endDateTime: Date, isOneShift: boolean): string {
    if (!startDateTime || !endDateTime) {
        return '';
    }

    if (isOneShift) {
        const dateStr = formatDate(startDateTime);
        const startTimeStr = formatTime(startDateTime);
        const endTimeStr = formatTime(endDateTime);

        return `${dateStr}, ${startTimeStr}&nbsp;-&nbsp;${endTimeStr}`;
    }

    const startDateTimeString = formatDateShort(startDateTime);
    const endDateTimeString = formatDateShort(endDateTime);

    return `${startDateTimeString}&nbsp;- ${endDateTimeString}`;
}

function formatCardDateTime(
    startDateTime: Date | undefined,
    endDateTime: Date | undefined,
    isOneShift: boolean,
    isOnlyDate = false,
): string {
    if (!startDateTime || !endDateTime) {
        return '';
    }

    if (isOneShift) {
        const dateStr = formatDate(startDateTime);
        const startTimeStr = formatTime(startDateTime);
        const endTimeStr = formatTime(endDateTime);
        const result = isOnlyDate
            ? `${dateStr}`
            : `${dateStr}, ${startTimeStr}&nbsp;-&nbsp;${endTimeStr}`;

        return result;
    }

    const startDateTimeString = formatDateShort(startDateTime);
    const endDateTimeString = formatDateShort(endDateTime);

    return `From ${startDateTimeString} to ${endDateTimeString}`;
}

export function formatPlacementCardDateTime(placement: PlacementInterface): string {
    return formatCardDateTime(
        placement.nextShiftDateTimePair?.startDateTime || placement.placementStartDate,
        placement.nextShiftDateTimePair?.endDateTime || placement.placementEndDate,
        !!placement.nextShiftDateTimePair,
    );
}

export function formatJobCardDateTime(jobData: JobDataInterface, offerType: JobOfferType = JobOfferType.JOB): string {
    return formatCardDateTime(
        jobData?.firstShiftDateTimePair?.startDateTime,
        jobData?.lastShiftDateTimePair?.endDateTime,
        isOneShiftJobOrPlacement(jobData),
        offerType === JobOfferType.COLLECTION,
    );
}
