import { ClientSectionsEnum } from '../enums/client-sections.enum';

export const ClientRoutesDictionary: Record<ClientSectionsEnum, string> = {
    [ClientSectionsEnum.AUTH]: 'auth',
    [ClientSectionsEnum.BOOKING]: 'booking',
    [ClientSectionsEnum.DATA_COLLECTION]: 'data-collection',
    [ClientSectionsEnum.HELP]: 'help',
    [ClientSectionsEnum.HOME]: 'home',
    [ClientSectionsEnum.LOGOUT]: '/logout',
    [ClientSectionsEnum.MESSAGING]: 'messaging',
    [ClientSectionsEnum.SELECT_APPLICATION]: '/select-app',
    [ClientSectionsEnum.SETTINGS]: 'settings',
    [ClientSectionsEnum.STAFF]: 'staff',
};
