<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button text=""></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-text-center">Saved Filters</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-list>
        <jobs-placements-saved-filter-list-item
            *ngFor="let filter of savedFilters$ | async"
            [filter]="filter"
            (filterEvent)="filterEventHandler($event)">
        </jobs-placements-saved-filter-list-item>
    </ion-list>
</ion-content>

<ion-footer class="ion-padding-horizontal">
    <ion-toolbar>
        <ion-button (click)="close()" color="primary" fill="clear" slot="end">Close</ion-button>
    </ion-toolbar>
</ion-footer>
