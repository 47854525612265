import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { isObject } from 'lodash-es';

import { JobOfferType } from '../../enums/job-offer-type.enum';
import { isOneShiftJobOrPlacement, formatDate, formatShiftDateTime } from '../../helpers/jobs-placements-date-time.helper';
import { DateTimeNullablePairInterface } from '../../interfaces/date-time-pair.interface';
import { JobLikeType } from '../../types/job-item.type';

@Component({
    selector: 'jobs-placements-job-description',
    templateUrl: './job-description.component.html',
    styleUrls: ['./job-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDescriptionComponent {
    @Input() public isShowPayRate: boolean | null;
    @Input() public job: JobLikeType;

    public get isOfferTypeJob(): boolean {
        return this.job.offerType === JobOfferType.JOB;
    }

    public get isOfferTypeShift(): boolean {
        return this.job.offerType === JobOfferType.SHIFT;
    }

    public get isOneShiftJob(): boolean {
        return isOneShiftJobOrPlacement(this.job.jobData);
    }

    public get jobStartDate(): string {
        if (!this.hasJobData) {
            return '';
        }

        return formatDate(this.job.jobData?.firstShiftDateTimePair?.startDateTime || this.job.jobData?.startDate);
    }

    public get jobEndDate(): string {
        if (!this.hasJobData) {
            return '';
        }

        return formatDate(this.job.jobData?.lastShiftDateTimePair?.startDateTime || this.job.jobData?.endDate);
    }

    public get shiftType(): string {
        if (this.isOfferTypeJob || !this.isOneShiftJob || !this.job.jobData?.diary?.[0]) {
            return '';
        }

        return `${this.job.jobData?.diary?.[0]?.shiftName}`;
    }

    public get shiftDateTime(): string {
        if (!this.shift) {
            return '';
        }

        return formatShiftDateTime(this.shift.startDateTime, this.shift.endDateTime, this.isOneShiftJob);
    }

    public showPayRate(job: JobLikeType): boolean {
        return this.isShowPayRate && Boolean(job.jobData?.payRateAmount);
    }

    private get hasJobData(): boolean {
        return isObject(this.job.jobData);
    }

    private get shift(): DateTimeNullablePairInterface | undefined {
        return this.job.jobData?.firstShiftDateTimePair;
    }
}
