import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoleAccessGuard } from '../../guards/role-access.guard';

import { SelectClientComponent } from './select-client.component';

const routes: Routes = [
    {
        path: '',
        component: SelectClientComponent,
        canActivate: [RoleAccessGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        RoleAccessGuard,
    ],
})
export class SelectClientRoutingModule { }
