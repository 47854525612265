import { Pipe, PipeTransform } from '@angular/core';
import { currencyFormat } from '../helpers/currency-format.helper';
import { ChargeUnitInterface } from '../interfaces/charge-unit.interface';

@Pipe({
    name: 'payRate',
})
export class PayRatePipe implements PipeTransform {
    public transform(value: {
        payRateAmount?: string,
        payRate?: number,
        payRateChargeUnit?: string
        chargeUnit?: ChargeUnitInterface;
    }): string {
        const payRate = currencyFormat(value.payRateAmount || value.payRate);
        const paytUnit = value.payRateChargeUnit || value.chargeUnit?.ValueName;

        return `$${payRate} (${paytUnit}) + holiday pay`;
    }
}
