import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ComplexControlValueComponent } from './complex-control-value.component';

@NgModule({
    declarations: [ComplexControlValueComponent],
    exports: [ComplexControlValueComponent],
    imports: [
        CommonModule,
        IonicModule,
    ],
})
export class ComplexControlValueModule { }
