import { ENVIRONMENT_TOKEN, Environment } from '@actassa/api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { Observable, finalize, of, tap } from 'rxjs';

import { TimesheetExpenseV3 } from '../interfaces/timesheet-expense-v3.interface';
import { AbstractDataLoader } from '../../../abstracts/data-loader.service';

@Injectable()
export class TimesheetExpensesService extends AbstractDataLoader {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) protected readonly environment: Environment,
        private readonly http: HttpClient,
    ) {
        super(environment);
    }

    public load$(timesheetIds: Array<string>): Observable<Array<TimesheetExpenseV3>> {
        if (isEmpty(timesheetIds)) {
            return of([]);
        }

        this.startLoading();

        const params = new HttpParams({ fromObject: { timesheetIds } });
        const url = this.buildUrl('v3/timesheet/expenses');

        return this.http.get<Array<TimesheetExpenseV3>>(url, { params })
            .pipe(
                tap(() => this.stopLoading()),
                finalize(() => this.stopLoading()),
            );
    }
}
