<div>
    <ion-text color="dark">
        <h3> Updated {{ dateFormat(item.date) }} </h3>
    </ion-text>
</div>
<!-- <ul>
    <li *ngFor="let message of item.messages">
        <ion-text color="dark">
            <h3> {{ message }} </h3>
        </ion-text>
    </li>
</ul> -->
