import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'jobs-placements-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    @Input() public label: string | null;
}
