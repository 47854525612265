import { DestroyService } from '@actassa/shared';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { FiltersService } from './services/filters.service';

@Component({
    selector: 'app-messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
})
export class MessagingComponent implements OnInit {
    constructor(
        private readonly filtersService: FiltersService,
        private readonly onDestroy$: DestroyService,
    ) { }

    public ngOnInit(): void {
        this.filtersService.getList$()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
    }
}
