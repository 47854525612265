import { ApplicationsEnum, STORE_WRAPPER_TOKEN, StoreWrapperInterface } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { isEmpty } from 'lodash-es';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, switchMap, map, concatAll, take, distinctUntilChanged, tap } from 'rxjs/operators';

import { JobsPlacementsState } from '../+state/app-state/app.state';
import { JobChangeStatusInterface } from '../interfaces/job-change-status.interface';
import { ShiftChangeStatusInterface } from '../interfaces/shift-change-status.interface';
import { JobsService } from '../services/jobs.service';

import { ShiftsService } from './shifts.service';

@Injectable({
    providedIn: 'root',
})
export class SchedulerService {
    @Select(JobsPlacementsState.awaitingJobStatusChanges$) public jobStatuses$: Observable<Array<any>>;
    @Select(JobsPlacementsState.awaitingShiftStatusChanges$) public shiftStatuses$: Observable<Array<any>>;

    private subscriptions: Subscription;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
    ) {
        this.isActionSubscriptionEnabled$
            .pipe(
                distinctUntilChanged(),
                tap((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        const initSubscription = this.init$().subscribe();

                        this.subscriptions = new Subscription();
                        this.subscriptions.add(initSubscription);

                        return;
                    }

                    this.subscriptions?.unsubscribe();
                }),
            )
            .subscribe();
    }

    private get isActionSubscriptionEnabled$(): Observable<boolean> {
        const { isAuthenticated$, app$ } = this.storeWrapper;
        const enabledRoles$ = app$
            .pipe(
                map((app: ApplicationsEnum) => app?.startsWith('CANDIDATE')),
            );

        return combineLatest([isAuthenticated$, enabledRoles$])
            .pipe(
                map((keys: Array<boolean>) => keys.every(Boolean)),
            );
    }

    private init$(): Observable<void> {
        return merge(
            this.sendAwaitingJobStatuses$(),
            this.sendAwaitingShiftStatuses$(),
        );
    }

    private sendAwaitingJobStatuses$(): Observable<any> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.jobStatuses$.pipe(take(1))),
                filter(statuses => !isEmpty(statuses)),
                switchMap((statuses: Array<JobChangeStatusInterface>) => of(...statuses)),
                map((status: JobChangeStatusInterface) => this.jobsService.sendJobStatusToServer$(status)),
                concatAll(),
            );
    }

    private sendAwaitingShiftStatuses$(): Observable<any> {
        return this.storeWrapper.isNetworkConnected$
            .pipe(
                filter((isConnected: boolean) => isConnected),
                switchMap(() => this.shiftStatuses$.pipe(take(1))),
                filter(statuses => !isEmpty(statuses)),
                switchMap((statuses: Array<ShiftChangeStatusInterface>) => of(...statuses)),
                map((status: ShiftChangeStatusInterface) => this.shiftsService.sendShiftStatusToServer$(status)),
                concatAll(),
            );
    }
}
